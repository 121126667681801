import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import {
  LabelStyled,
  InputStyled,
  ControlStyled,
  ControlTextStyled,
} from './style';

const Switch = forwardRef((props, ref) => {
  const { id, label, checked, controlText, width, theme, size } = props;

  return (
    <LabelStyled htmlFor={id}>
      <InputStyled ref={ref} type="checkbox" {...props} />
      {label && <span>{label}</span>}
      <ControlStyled controlText={controlText} width={width} size={size}>
        {controlText && (
          <ControlTextStyled checked={checked}>
            {checked ? controlText.checked : controlText.unchecked}
          </ControlTextStyled>
        )}
      </ControlStyled>
    </LabelStyled>
  );
});

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  controlText: PropTypes.objectOf(PropTypes.any),
  width: PropTypes.number,
  theme: PropTypes.objectOf(PropTypes.any),
};

Switch.defaultProps = {
  id: '',
  label: '',
  width: 78,
  controlText: null,
};

export default Switch;
