import styled, { css } from 'styled-components';

const placeholderMessage = (size, theme) => ({
  fontSize: '14px',
  lineHeight: '17px',
  textAlign: 'center',
  padding: size === 'small' ? '6px 14px' : '11px 20px',
  boxShadow: `0 4px 11px ${theme.colors.common.black}1A`,
  color: theme.colors.gray5,
});

export const styles = ({
  size,
  customStyles = {},
  shouldControlInputValue,
}) => {
  const {
    control,
    input,
    valueContainer,
    indicatorSeparator,
    indicatorsContainer,
    dropdownIndicator,
    menu,
    menuList,
    noOptionsMessage,
    loading,
    option,
    group,
    singleValue,
    placeholder,
    loadingIndicator,
    multiValue,
    multiValueRemove,
    ...restStyles
  } = customStyles;
  const getCustomStyles = (elem, state) => elem && elem(state);
  let controlState = {};
  return {
    control: (provided, state) => {
      controlState = state;
      return {
        ...provided,
        minHeight: size === 'small' ? '30px' : '38px',
        borderColor: state.isFocused
          ? state.theme.colors.brand.light.hover
          : state.theme.colors.border.default,
        boxShadow: 'none',
        borderRadius: 2,
        fontSize: '14px',
        lineHeight: '17px',
        color: state.theme.colors.text.variant1,
        outline: state.isFocused && 'none',
        cursor: 'pointer',
        transition: 'all 0.2s',
        background: state.theme.isDark
          ? state.isFocused
            ? state.theme.colors.action.hover
            : 'transparent'
          : state.theme.colors.common.white,
        '&:hover': {
          borderColor: state.isFocused
            ? state.theme.colors.brand.light.hover
            : state.theme.colors.border.default,
          background: state.theme.colors.action.variant1,
        },
        '.select__indicators': {
          opacity: 0,
          transition: 'all 0.2s',
        },
        '& .select__indicators': {
          opacity: state.isFocused ? 1 : 0,
        },
        '&:hover .select__indicators': {
          opacity: 1,
        },
        ...getCustomStyles(control, state),
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      padding: size === 'small' ? '6px 8px' : '10.5px 10px',
      ...getCustomStyles(valueContainer, state),
    }),
    singleValue: (provided, state) => ({
      ...provided,
      marginLeft: 0,
      color: state.theme.colors.text.variant1,
      ...(shouldControlInputValue &&
        controlState.isFocused && {
          color: state.theme.colors.gray4,
        }),
      ...getCustomStyles(singleValue, state),
    }),
    input: (provided, state) => ({
      ...provided,
      maxHeight: '17px',
      margin: 0,
      padding: 0,
      color: state.theme.colors.text.default,
      ...(!state.isFocused && {
        width: 0,
      }),
      '& > input.select__input': {
        opacity: 1,
      },
      ...getCustomStyles(input, state),
    }),
    indicatorSeparator: (provided, state) => ({
      display: 'none',
      ...getCustomStyles(indicatorSeparator, state),
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      '& svg': {
        marginRight: 6,
        fill: state.theme.colors.gray6,
      },
      ...getCustomStyles(indicatorsContainer, state),
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: size === 'small' ? '4px' : '8px',
      ...getCustomStyles(dropdownIndicator, state),
    }),
    menu: (provided, state) => ({
      ...provided,
      margin: 0,
      border: 'none',
      borderRadius: 0,
      boxShadow: `0 4px 11px ${state.theme.colors.common.contrast}1A`,
      backgroundColor: state.theme.colors.background.variant1,
      ...getCustomStyles(menu, state),
    }),
    menuList: (provided, state) => ({
      ...provided,
      whiteSpace: 'normal',
      padding: 0,
      ...getCustomStyles(menuList, state),
    }),
    noOptionsMessage: (provided, state) =>
      placeholderMessage(size, state.theme),
    loadingMessage: (provided, state) => placeholderMessage(size, state.theme),
    option: (provided, state) => ({
      ...provided,
      fontSize: '12px',
      lineHeight: '16px',
      padding: size === 'small' ? '6px 14px' : '11px 20px',
      color: state.theme.colors.text.variant1,
      backgroundColor: state.isSelected
        ? state.theme.colors.action.variant2
        : state.isFocused
        ? state.theme.colors.action.variant2
        : null,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: state.theme.colors.action.variant2,
      },
      ...getCustomStyles(option, state),
    }),
    group: (provided, state) => ({
      ...provided,
      ...getCustomStyles(group, state),
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: state.theme.colors.gray5,
      ...getCustomStyles(placeholder, state),
    }),
    loadingIndicator: (provided, state) => ({
      ...provided,
      color: state.theme.colors.gray5,
      ...getCustomStyles(loadingIndicator, state),
    }),
    multiValue: (provided, state) => ({
      ...provided,
      ...getCustomStyles(multiValue, state),
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      ...getCustomStyles(multiValueRemove, state),
    }),
    ...restStyles,
  };
};

export const Wrapper = styled.div`
  position: relative;
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}
`;

export const SelectGroupStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 11px;
    margin-right: 2px;
  }
`;
export const OptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  padding-bottom: 6px;
  .label {
    font-size: 12px;
  }
  .email {
    font-size: 12px;
    line-height: 10px;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const SelectBadgeStyled = styled.span`
  display: inline-flex;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: normal;
  line-height: 1;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text.dark};
  background-color: ${({ theme }) => theme.colors.lightGray2};
`;

export const Label = styled.label`
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text.variant1};
`;

export const ListItem = styled.div`
  display: flex;
  height: 37px;
  align-items: center;
  padding: 0px 14px;
  .label {
    font-size: 12px;
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.action.variant2};
  }
  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: ${({ theme }) => theme.colors.action.variant2};
    `}
`;

export const SelectedItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .picker-item-name {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.text.default};
  }
  div[role='button'] {
    display: flex;
    color: ${({ theme }) => theme.colors.gray5};
    margin-left: 10px;
  }
`;

export const AddNewOptionButton = styled.button`
  padding: 8px 12px;
  color: ${({ theme }) => theme.colors.brand.light.base};
  font-size: 12px;
  height: 37px;
  display: flex;
  align-items: center;
  outline: 0;
  border: 0;
  font-weight: 600;
  border-top: 1px solid ${({ theme }) => theme.colors.border.default};
  background-color: ${({ theme }) => theme.colors.background.variant1};
  width: 100%;
  svg {
    margin-right: 10px;
    fill: ${({ theme }) => theme.colors.brand.light.base};
  }
`;
export const RemoveButtonPlaceholder = styled.span`
  width: 10px;
`;
