import React from 'react';

import { PopupStyles, TimePickerStyled } from './style';

const TimePicker = (props) => (
  <>
    <PopupStyles />
    <TimePickerStyled {...props} />
  </>
);

export default TimePicker;
