import { animated } from 'react-spring';
import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.second};

  textarea,
  textarea {
    display: block;
    width: 100%;
    border: none;
    resize: none;
    background: transparent;
  }

  textarea:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.gray7};
  }

  :placeholder {
    color: ${({ theme }) => theme.colors.gray5};
  }

  div:nth-child(2),
  div:nth-child(3) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
  }
`;

export const RestrictedContainer = styled(Container)`
  cursor: progress !important;
  pointer-events: none;
`;

export const Tabs = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 7px;
  font-size: 15px;
  line-height: 18px;
  border-bottom: 1px solid #dddddd;
  overflow-x: visible;
  overflow-y: hidden;
  scroll-behavior: smooth;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const TabItem = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  padding: 24px 12px;
  border: none;
  background: none;
  font-size: 14px;
  line-height: 17px;
  height: 17px;
  font-weight: 500;
  color: ${({ isActive, color, theme }) =>
    isActive ? theme.colors.brand.light.base : color || theme.colors.gray5};
  cursor: pointer;
  white-space: nowrap;

  svg {
    margin-right: 4px;
  }

  .timer-time {
    margin-left: 5px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 100%;
        height: 2px;

        background: ${({ theme }) => theme.colors.brand.light.base};
      }
    `}

  @media(max-width: 1372px) {
    margin-right: 14px;
  }
`;

export const TypingArea = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  ${({ isNote }) => isNote && 'background-color: #FFF5E4;'}
  .message-textarea {
    font-family: 'Inter';
    font-size: 14px;
    border: none;
    outline: none !important;
    box-shadow: none !important;
    min-height: 30px;
  }
`;

export const ToolingArea = styled.div`
  padding: 0px 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Tools = styled.div``;

export const FilterInput = styled(animated.input)`
  width: 100%;
  padding: 17px 19px;
  overflow: hidden;
  background-color: transparent;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  outline: none;
  border: none;
  border-bottom: 1px solid #dddddd;
`;
