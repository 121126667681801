import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable, useRowSelect } from 'react-table';
import ReactPaginate from 'react-paginate';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import TableBody from './TableBody';
import TableBodyDND from './TableBodyDND';
import * as Styled from './styles';

const Table = ({
  columns,
  rowsData,
  onRowClick,
  isLoading,
  className,
  onPageChange,
  activeRowId,
  selection = false,
  onSelectedRowsChange,
  isDND,
  setData,
  reorderDataInDB,
  placeholder,
}) => {
  const { meta, data } = rowsData || {};

  const reorderData = (startIndex, endIndex) => {
    const newData = [...data];
    const [movedRow] = newData.splice(startIndex, 1);
    newData.splice(endIndex, 0, movedRow);
    reorderDataInDB({
      source: startIndex,
      destination: endIndex,
      reorderedData: newData,
    });
    setData(newData);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      autoResetHiddenColumns: false,
      manualPagination: true,
      reorderData,
      getRowId: (row, relativeIndex, parent) =>
        row.id ||
        row.product_id ||
        (parent ? [parent.id, relativeIndex].join('.') : relativeIndex),
    },
    useRowSelect,
    hooks => {
      if (selection) {
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            width: 24,
            minWidth: 24,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                id={row.id}
              />
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  const handleDragEnd = result => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) return;
    reorderData(source.index, destination.index);
  };

  const clickRowHandler = row => {
    if (typeof onRowClick === 'function') {
      onRowClick(row.original);
    }
  };

  useEffect(() => {
    onSelectedRowsChange(selectedFlatRows);
  }, [selectedRowIds]);

  return (
    <Styled.TableWrapper className={className}>
      {data?.length ? (
        <Styled.Table {...getTableProps()}>
          <Styled.TableHeader>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                      },
                    })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </Styled.TableHeader>
          {isDND ? (
            <TableBodyDND
              getTableBodyProps={getTableBodyProps}
              rows={rows}
              prepareRow={prepareRow}
              clickRowHandler={clickRowHandler}
              activeRowId={activeRowId}
              handleDragEnd={handleDragEnd}
            />
          ) : (
            <TableBody
              getTableBodyProps={getTableBodyProps}
              rows={rows}
              prepareRow={prepareRow}
              clickRowHandler={clickRowHandler}
              activeRowId={activeRowId}
            />
          )}
        </Styled.Table>
      ) : (
        ''
      )}
      {isLoading && (
        <Styled.Loader>
          <Spinner />
        </Styled.Loader>
      )}
      {!data?.length && !isLoading && (
        <Styled.Placeholder>{placeholder}</Styled.Placeholder>
      )}
      <Styled.BottomPanel>
        {meta?.last_page > 1 && (
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            forcePage={meta.current_page - 1}
            breakLabel="..."
            breakClassName="break-me"
            pageCount={meta.last_page}
            onPageChange={value =>
              onPageChange && onPageChange(value.selected + 1)
            }
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        )}
        {!!meta?.total && (
          <Styled.Info>
            {meta.from}-{meta.to} of {meta.total}
          </Styled.Info>
        )}
      </Styled.BottomPanel>
    </Styled.TableWrapper>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any),
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  onRowClick: PropTypes.func,
  onPageChange: PropTypes.func,
  onSelectedRowsChange: PropTypes.func,
  rowsData: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  selection: PropTypes.bool,
  activeRowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isDND: PropTypes.bool,
  setData: PropTypes.func,
  reorderDataInDB: PropTypes.func,
  placeholder: PropTypes.string,
};

Table.defaultProps = {
  className: '',
  isLoading: false,
  error: '',
  onRowClick: () => {},
  onPageChange: () => {},
  onSelectedRowsChange: () => {},
  columns: [],
  rowsData: { data: [], meta: {} },
  selection: false,
  activeRowId: '',
  isDND: false,
  setData: () => {},
  reorderDataInDB: () => {},
  placeholder: 'There is no data to display',
};

export default memo(Table);
