import PropTypes from 'prop-types';
import { forwardRef, useEffect, useRef } from 'react';
import { Checkbox } from 'src';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;
  useEffect(() => {
    if (resolvedRef.current) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);

  return (
    <Checkbox
      type="checkbox"
      inputRef={resolvedRef}
      indeterminate={indeterminate}
      {...rest}
    />
  );
});
IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool,
};

IndeterminateCheckbox.defaultProps = {
  indeterminate: false,
};

export default IndeterminateCheckbox;
