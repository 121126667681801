import styled, { css } from 'styled-components';

export const SelectedValue = styled.div`
  .label {
    font-size: 12px;
    margin-left: 8px;
  }
`;

export const TitleStyled = styled.h3`
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  letter-spacing: -0.43;
  color: ${({ theme }) => theme.colors.text?.label};
`;

export const StyledContainer = styled.div`
  .card {
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.background?.default};
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.gray4};
  }
  .box {
    display: flex;
    align-items: center;
  }
  .select-wrapper {
    width: 100%;
    margin-left: 15px;
  }
`;
export const ListItem = styled.div`
  display: flex;
  height: 37px;
  align-items: center;
  padding: 0px 14px;
  .label {
    font-size: 12px;
    margin-left: 8px;
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.action.variant2};
  }
  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: ${({ theme }) => theme.colors.action.variant2};
    `}
`;

export const FooterStyled = styled.div``;
