import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonStyled,
  LinkStyled,
  SpinnerStyled,
  IconContainer,
} from './style';

const Button = forwardRef(
  (
    {
      children,
      isDisabled,
      isLoader,
      styleMode,
      size,
      className,
      href,
      target,
      iconLeft,
      ...props
    },
    ref
  ) => {
    if (href) {
      return (
        <LinkStyled
          ref={ref}
          href={href}
          target={target}
          className={className}
          disabled={isDisabled || isLoader}
          styleMode={styleMode}
          size={size}
        >
          {iconLeft && !isLoader && <IconContainer>{iconLeft}</IconContainer>}
          {isLoader ? <SpinnerStyled /> : children}
        </LinkStyled>
      );
    }

    return (
      <ButtonStyled
        ref={ref}
        className={className}
        disabled={isDisabled || isLoader}
        styleMode={styleMode}
        size={size}
        {...props}
      >
        {iconLeft && !isLoader && <IconContainer>{iconLeft}</IconContainer>}
        {isLoader ? <SpinnerStyled /> : children}
      </ButtonStyled>
    );
  }
);

Button.propTypes = {
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  isLoader: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  styleMode: PropTypes.oneOf(['default', 'outline', 'danger']),
  full: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string,
  iconLeft: PropTypes.element,
};

Button.defaultProps = {
  children: '',
  isDisabled: false,
  isLoader: false,
  className: 'simple-button',
  size: 'medium',
  styleMode: 'default',
  full: false,
  href: '',
  target: '',
  iconLeft: null,
};

export default Button;
