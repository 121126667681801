import styled from 'styled-components';
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow.svg';

const Box = styled.span`
  width: 40px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease-in-out all;
  transform: rotate(-90deg);
  &:hover {
    padding-top: 12px;
  }
  svg {
    fill: #999999;
    height: 8px;
    width: 15px;
  }
`;

const More = (props) => (
  <Box {...props}>
    <Arrow />
  </Box>
);

export default More;
