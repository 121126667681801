import ContentLoader from 'react-content-loader';
import { useTheme } from 'styled-components';

export default (props) => {
  const theme = useTheme();
  return (
    <ContentLoader
      speed={2}
      width={334}
      height={71}
      viewBox="0 0 334 71"
      backgroundColor={theme.colors.background.variant2}
      foregroundColor={theme.colors.background.dark}
      {...props}
    >
      <rect x="20" y="6" rx="2" ry="2" width="32" height="32" />
      <rect x="60" y="16" rx="2" ry="2" width="111" height="15" />
    </ContentLoader>
  );
};
