import { useEffect, useState } from 'react';
import { useSpring } from 'react-spring';
import { match } from 'ts-pattern';

export const useAnimate = ({ type }) => {
  const [showAnim, setShowAnim] = useState(false);

  const anim = match(type)
    .with('slideInLeft', () => ({
      transform: showAnim ? 'translateX(0)' : 'translateX(-500px)',
    }))
    .with('slideInRight', () => ({
      transform: showAnim ? 'translateX(0)' : 'translateX(500px)',
    }))
    .with('slideInBottom', () => ({
      transform: showAnim ? 'translateY(0)' : 'translateY(44px)',
    }))
    .with('slideInTop', () => ({
      transform: showAnim ? 'translateY(0)' : 'translateY(-44px)',
    }))
    .with('grow', () => ({
      maxHeight: showAnim ? '0' : '44px',
    }))
    .otherwise(() => {});

  const contentProps = useSpring({
    ...anim,
    opactiy: showAnim ? 1 : 0,
    delay: 200,
  });
  useEffect(() => {
    setShowAnim(true);
    return () => {
      setShowAnim(false);
    };
  }, []);

  return {
    contentProps,
  };
};
