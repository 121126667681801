import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import { toHHMMSS } from 'src/utils';
import Breadcrumb from '../breadcrumb';
import { initialTabs, initialTabsNames } from '../config';
import { chatInputRef } from '../hook/useInputFocus';
import Suggestions from '../Suggestions';
import * as Styled from './style';

const Input = ({
  text,
  placeholder,
  activeTab,
  activeTabConfig,
  suggestionsState,
  onSelectWithMouse,
  onChangeTab,
  breadcrumb,
  isTimerActive,
  tabs,
  tabsNames,
  timer,
  autoFocus,
  isLoading,
  tools,
  setText,
  isSelectable,
  filterText,
  setFilterText,
  currentAction,
  unqueueBreadcrumb,
  getOptions,
  handleChange,
}) => {
  const isNote = activeTab === tabsNames.NOTE;
  const textPlaceholder = isNote
    ? 'Display an internal note when this flow is triggered.'
    : 'Set a custom message the bot will reply when this flow is triggered.';
  const Container = !isLoading ? Styled.Container : Styled.RestrictedContainer;
  return (
    <Container>
      <Styled.Tabs>
        {Object.values(tabs)
          .filter((tab) => !tab?.parentTab)
          .map((tab) => {
            const active =
              tab.code === activeTab || tab.code === tabs[activeTab]?.parentTab;
            return (
              <Styled.TabItem
                key={`${tab.name}-${tab.code}`}
                type="button"
                isActive={active}
                className={`${active ? 'active-tab' : ''} tab`}
                onClick={() => onChangeTab(tab.code)}
                id={'tab-' + tab.name}
              >
                {tab.icon}
                {tab.name}
                {isTimerActive && tab.code === tabsNames.TIME && (
                  <span className="timer-time">{toHHMMSS(timer)}</span>
                )}
              </Styled.TabItem>
            );
          })}
      </Styled.Tabs>
      {!isSelectable && (
        <Styled.TypingArea isNote={isNote}>
          <TextareaAutosize
            maxRows={8}
            minRows={5}
            type="text"
            className="message-textarea"
            cacheMeasurements
            placeholder={textPlaceholder || ''}
            value={text}
            onChange={(event) => setText(event.target.value)}
            autoFocus={autoFocus}
            ref={chatInputRef}
          />
        </Styled.TypingArea>
      )}

      {breadcrumb.length > 0 && (
        <Breadcrumb onClick={unqueueBreadcrumb} actions={breadcrumb} />
      )}

      {Boolean(tools.length) && (
        <Styled.ToolingArea>
          <Styled.Tools>{tools.map((tool) => tool)}</Styled.Tools>
        </Styled.ToolingArea>
      )}
      {suggestionsState.tab === activeTab && (
        <Suggestions
          suggestions={suggestionsState.tabSuggestions}
          suggestionType={tabs[activeTab]?.suggestionType}
          selectedIndex={suggestionsState.activeSuggestion}
          showSelect={suggestionsState.showSelect}
          suggestionId={suggestionsState.id}
          suggestionPayload={suggestionsState.payload}
          activeTab={activeTab}
          isLoading={isLoading}
          showSuggestions={suggestionsState.showSuggestions}
          currentAction={currentAction}
          onSelect={onSelectWithMouse}
          isSelectable={isSelectable}
          tabs={tabs}
          getOptions={getOptions}
          handleChange={handleChange}
          hasBreadcrumb={breadcrumb.length > 0}
          breadcrumb={breadcrumb}
        />
      )}
    </Container>
  );
};

Input.propTypes = {
  text: PropTypes.string,
  placeholder: PropTypes.string,
  activeTab: PropTypes.string,
  suggestionsState: PropTypes.objectOf(PropTypes.any),
  onSelectWithMouse: PropTypes.func,
  onChangeTab: PropTypes.func,
  isTimerActive: PropTypes.bool,
  tabs: PropTypes.objectOf(PropTypes.any),
  tabsNames: PropTypes.objectOf(PropTypes.any),
  timer: PropTypes.number,
  autoFocus: PropTypes.bool,
  isLoading: PropTypes.bool,
  tools: PropTypes.arrayOf(PropTypes.element),
  currentAction: PropTypes.object.isRequired,
  unqueueBreadcrumb: PropTypes.func.isRequired,
  breadcrumb: PropTypes.array,
  getOptions: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
};

Input.defaultProps = {
  text: '',
  placeholder: '',
  activeTab: initialTabsNames.REPLY,
  suggestionsState: {
    tab: null,
    showSuggestions: false,
  },
  tabs: { [initialTabsNames.REPLY]: initialTabs[initialTabsNames.REPLY] },
  tabsNames: initialTabsNames,
  isTimerActive: false,
  timer: Date.now(),
  autoFocus: false,
  isLoading: false,
  tools: [],
  onSelectWithMouse: () => {},
  onChangeTab: () => {},
  breadcrumb: [],
  getOptions: () => {},
};

export default Input;
