
/**
 * Extract attributes with values from initial query
 * @param {Object} query
 * @returns {Array}
 */
export function extractAttributes(query) {
  const attributes = [];
  const recurse = root => {
    if (!root) return [];

    root.rules.forEach(rule => {
      if (rule.field) {
        attributes.push({
          name: rule.field,
          value: rule.value,
        });
      } else {
        recurse(rule);
      }
    });

    return attributes;
  };

  return recurse(query);
}

/**
 * Generate a map of value types and a map of values for
 * its respective attribute by id
 * @param {Array} attributes
 * @returns {Object}
 */
export function generateAttributeMap(attributes) {
  return attributes.reduce(
    (obj, { id, type, values = [] }) => {
      const reduceObj = obj;
      reduceObj.types[id] = type;
      reduceObj.values[id] = values.map(({ value, displayValue: label }) => ({
        value,
        label,
      }));

      return obj;
    },
    { types: {}, values: {} }
  );
}

/**
 * Prepare helper functions for react-querybuilder module
 * @param {Array} attributes
 * @returns {Object}
 */
export function prepareQueryBuilderHelpers(attributes, operatorFilter, operatorTypes) {
  const getValues = field => attributes.values[field] || [];
  const getValueEditorType = field => {
    switch (attributes.types[field]) {
      case 'matching_object':
        return 'react-select-creatable';
      case 'object':
        return 'react-select';
      case 'array':
        return 'react-select';
      case 'array_intersect':
          return 'react-select';
      case 'day_of_week':
        return 'react-select-week';
      case 'time_of_day':
        return 'time';
      case 'date':
        return 'date';
      case 'string':
      case 'number':
        return 'react-input';
      default:
        return 'text';
    }
  };
  const getInputType = field => {
    switch (attributes.types[field]) {
      case 'number':
        return 'number';
      default:
        return 'text';
    }
  };
  const getOperators = field => {
    return operatorFilter
      ? operatorFilter(operatorTypes[attributes.types[field]] || [])
      : operatorTypes[attributes.types[field]];
  };

  return {
    getValues,
    getValueEditorType,
    getInputType,
    getOperators,
  };
}
