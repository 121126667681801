import AR from 'react-avatar';
import { createGlobalStyle } from 'styled-components';
import darkTheme from './darkTheme';
import lightTheme from './lightTheme';

export const theme = {
  light: lightTheme,
  dark: darkTheme,
};
export const getTheme = (theme) =>
  (theme || localStorage.getItem('theme')) === 'dark' ? darkTheme : lightTheme;
export const avatarColors = [
  getTheme().colors.green3,
  getTheme().colors.purple1,
  getTheme().colors.orange2,
];
export const getRandomAvatarColor = (id) => AR.getRandomColor(id, avatarColors);
const exposeThemeColorsToCssVariable = (colors, name = '') => {
  return Object.entries(colors || {}).reduce(
    (init, [colorName, colorValue]) =>
      (init +=
        typeof colorValue === 'string'
          ? `--${name ? name + '-' : ''}${colorName}: ${colorValue};`
          : exposeThemeColorsToCssVariable(
              colorValue,
              `${name ? name + '-' : ''}${colorName}`
            )),
    ''
  );
};
export const GlobalStyles = createGlobalStyle`
  :root {
    color-scheme: ${({ theme }) => (theme.isDark ? 'dark' : 'light')};
    ${({ theme }) => exposeThemeColorsToCssVariable(theme.colors)}
  }
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  body {
    background: ${({ theme }) => theme.colors.background.default};
    color: ${({ theme }) => theme.colors.text.default};
    transition: background 0.25s;
  }`;
