import { animated } from 'react-spring';
import styled from 'styled-components';
import { ReactComponent as Arrow } from '../../../assets/icons/arrow.svg';

const BreadcrumbBox = styled(animated.div)`
  display: flex;
  align-items: center;
  padding: 15px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.border.default}!important;
  color: ${({ theme }) => theme.colors.brand.light.base};
  border-radius: 0 !important;
  &:hover {
    .icon {
      padding-left: 0;
    }
  }
`;

const Icon = styled.span`
  display: flex;
  align-items: center;
  width: 45px;
  &.icon {
    transition: 0.4s ease-in-out all;
    padding-left: 12px;
  }
  svg {
    fill: ${({ theme }) => theme.colors.brand.light.base};
    transform: rotate(90deg);
    height: 8px;
    width: 15px;
  }
`;

const Label = styled.span`
  display: flex;
  align-items: center;
`;

const Breadcrumb = ({ actions, ...props }) => {
  const label = actions.map((action) => action.name).join(' / ');

  return (
    <BreadcrumbBox {...props}>
      <Icon className="icon">
        <Arrow />
      </Icon>
      <Label>{label}</Label>
    </BreadcrumbBox>
  );
};

export default Breadcrumb;
