import React, { Children } from 'react';
import * as Styled from './style';

const RadioButton = ({
  htmlFor,
  id,
  name,
  value,
  onChange,
  checked,
  label,
  inputRef,
  className,
  children,
  ...rest
}) => (
  <Styled.Label htmlFor={htmlFor} className={className}>
    <input
      type="radio"
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      checked={checked}
      ref={inputRef}
      {...rest}
    />
    <Styled.Control />
    <span>{label}</span>
    {children}
  </Styled.Label>
);

export default RadioButton;
