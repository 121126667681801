import React from 'react';
import { theme } from 'src';
import { ThemeProvider } from 'styled-components';
import 'src/styles/main.css';

type Props = {
  children: any;
  themeMode: 'dark' | 'light';
};

export const JasmineThemeProvider = ({ children, themeMode }: Props) => {
  return (
    <ThemeProvider theme={theme[themeMode || 'light']}>
      {children}
    </ThemeProvider>
  );
};
