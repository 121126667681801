/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import QueryBuilder from 'react-querybuilder';
import { ReactComponent as Info } from 'src/assets/icons/info.svg';
import { ticketingLogos } from '../config';
import ConfigureFlowBlock from '../ConfigureFlowBlock';
import {
  FiltersStyled,
  MessageStyled,
  TimezoneSelectContainer,
} from '../style';
import { generateAttributeMap, prepareQueryBuilderHelpers } from '../utilities';
import ValueEditor from '../ValueEditor';
import CustomRemoveButton from './CustomRemoveButton';
import CustomSelect from './CustomSelect';
import CustomAddAction from './CustomAddAction';
import { emptyValueRequiredOperators } from 'src/utils/flow-filters';
import Select from 'src/components/Select';
import { SelectAsync, SelectAsyncPaginate } from 'src/components';
import { Tooltip } from 'src/components/Tooltip/style';

const FilterEditor = ({
  isFilterAttributesFetching,
  error,
  info,
  flow,
  onChangeFilters,
  attributes,
  ticketService,
  searchAttributeValues,
  operatorFilter,
  timezoneSelectProps,
  operatorTypes,
}) => {
  const addRuleActionEl = useRef(null);
  const mapingAttributes = generateAttributeMap(attributes);
  const fields = attributes.map(({ id, display_name }) => ({
    name: id.toString(),
    label: display_name,
  }));

  const { getValues, getValueEditorType, getInputType, getOperators } =
    prepareQueryBuilderHelpers(mapingAttributes, operatorFilter, operatorTypes);

  const addGroupActionHandler = (event, handleOnClick) => {
    event.persist();
    handleOnClick(event);

    setTimeout(() => {
      addRuleActionEl.current.click();
    }, 0);
  };

  const resetRuleValueOnOperatorChange = (query) => {
    if (query?.rules) {
      query.rules.forEach((rule) => {
        if (rule.rules) {
          resetRuleValueOnOperatorChange(rule);
        } else if (emptyValueRequiredOperators.includes(rule.operator)) {
          rule.value = '';
        }
      });
    }
  };

  return (
    <ConfigureFlowBlock
      title="Apply filters"
      icon={ticketingLogos[ticketService]}
      isLoading={isFilterAttributesFetching}
    >
      {error && <MessageStyled>{error}</MessageStyled>}
      {info && (
        <MessageStyled typeMsg="info" typeView="block" icon={<Info />}>
          <span dangerouslySetInnerHTML={{ __html: info }} />
        </MessageStyled>
      )}
      {timezoneSelectProps && (
        <TimezoneSelectContainer>
          <p>Time zone</p>
          <SelectAsyncPaginate
            getOptionLabel={(option) =>
              option?.id ? `(UTC${option.utc_offset}) ${option.name}` : ''
            }
            size={'small'}
            placeholder="Select..."
            {...timezoneSelectProps}
          />
          <Info data-tip data-for="timezone-tip" />
          <Tooltip id="timezone-tip" effect="solid" place="right">
            <div>
              By default, the time zone is set to Eastern Time (EST), and it
              applies to Date, Day of Week and Time of Day
            </div>
          </Tooltip>
        </TimezoneSelectContainer>
      )}
      <FiltersStyled>
        <QueryBuilder
          query={flow.filterData}
          fields={fields}
          onQueryChange={(query) => {
            resetRuleValueOnOperatorChange(query);
            onChangeFilters(query);
          }}
          getOperators={getOperators}
          getValueEditorType={getValueEditorType}
          getInputType={getInputType}
          getValues={getValues}
          showCombinatorsBetweenRules
          showNotToggle={false}
          context={{
            searchAttributeValues,
            isSearchable: false,
            addRuleActionEl,
            addGroupActionHandler,
            emptyValueRequiredOperators,
          }}
          controlClassnames={{ fields: 'form-control' }}
          controlElements={{
            valueEditor: ValueEditor,
            combinatorSelector: CustomSelect,
            fieldSelector: CustomSelect,
            operatorSelector: CustomSelect,
            removeRuleAction: CustomRemoveButton,
            removeGroupAction: CustomRemoveButton,
            addRuleAction: CustomAddAction,
            addGroupAction: CustomAddAction,
          }}
        />
      </FiltersStyled>
    </ConfigureFlowBlock>
  );
};

FilterEditor.propTypes = {
  error: PropTypes.string,
  info: PropTypes.any,
  flow: PropTypes.objectOf(PropTypes.any),
  isFilterAttributesFetching: PropTypes.bool.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  attributes: PropTypes.arrayOf(PropTypes.any).isRequired,
};

FilterEditor.defaultProps = {
  error: '',
  info: '',
  flow: {},
  isFilterAttributesFetching: false,
  ticketService: null,
};

export default FilterEditor;
