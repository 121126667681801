import * as Styled from './styles';

const TableBody = ({
  getTableBodyProps,
  rows,
  prepareRow,
  clickRowHandler,
  activeRowId,
}) => (
  <tbody {...getTableBodyProps()}>
    {rows.map((row, i) => {
      prepareRow(row);
      return (
        <Styled.TableRow
          {...row.getRowProps()}
          onClick={() => clickRowHandler(row)}
          disabled={row.disabled}
          active={Number(activeRowId) === row.id}
        >
          {row.cells.map(cell => (
            <Styled.TableCell
              {...cell.getCellProps({
                style: {
                  minWidth: cell.column.minWidth,
                  width: cell.column.width,
                },
              })}
              customStyles={cell.column.customStyles || {}}
            >
              {cell.render('Cell')}
            </Styled.TableCell>
          ))}
        </Styled.TableRow>
      );
    })}
  </tbody>
);

export default TableBody;
