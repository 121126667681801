import styled from 'styled-components';

export const WrapperStyled = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: 16px;
`;

export const NotificationsStyled = styled.div`
  margin-top: 10px;
`;

export const TitleStyled = styled.h4`
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  letter-spacing: -0.43;
  fill: ${({ theme }) => theme.colors.text?.label};
`;

export const ActionFigure = styled.img`
  width: 40px;
  margin-left: -10px;
`;
