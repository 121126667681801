import styled, { ThemeConsumer } from 'styled-components';
import { rgba } from 'polished';
export const TableWrapper = styled.div`
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: 5px;
  position: relative;
  min-height: 100px;

  .pagination {
    margin-right: 8px;
    margin: 0;
    font-size: 14px;

    li {
      display: inline-block;
      min-width: 32px;
      height: 32px;
      margin-right: 8px;
      line-height: 30px;
      text-align: center;
      vertical-align: middle;
      background-color: ${({ theme }) => theme.colors.background.variant1};
      border: 1px solid ${({ theme }) => theme.colors.border.default};
      border-radius: 2px;
      outline: 0;
      cursor: pointer;
      user-select: none;
      transition: border-color 0.2s, color 0.2s;

      &.active {
        border-color: ${({ theme }) => theme.colors.border.active};
        color: ${({ theme }) => theme.colors.brand.light.base};
        background: ${({ theme }) => theme.colors.action.variant1};
      }

      &.disabled,
      &.disabled * {
        cursor: not-allowed;
        color: ${({ theme }) => theme.colors.gray4};
        background: transparent;
      }

      &:hover:not(.disabled),
      &:focus:not(.disabled) {
        border-color: ${({ theme }) => theme.colors.border.active};
        background: ${({ theme }) => theme.colors.action.variant1};
      }

      &:hover,
      &:focus a {
        color: ${({ theme }) => theme.colors.brand.light.hover};
      }
    }

    li:not(.disabled) a:focus:not(:active) {
      color: ${({ theme }) => theme.colors.brand.light.base};
    }

    a {
      display: block;
      padding: 0 6px;
      outline: none;
      color: ${({ theme }) => theme.colors.text.variant1};
    }

    .previous,
    .next {
      border: none;

      &:hover,
      &:focus {
        color: inherit;
      }
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`;

export const TableHeader = styled.thead`
  th {
    padding: 15px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
    border-top: 1px solid ${({ theme }) => theme.colors.border.default};
    font-size: 12px;
    font-weight: 700;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.text.variant2};
    background-color: ${({ theme }) => theme.colors.background.secondary};

    &.compact {
      padding-left: 0;
      padding-right: 0;
      min-width: 0;
    }
  }
`;

export const TableCell = styled.td`
  max-width: 240px;
  padding: 14px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.default};

  span {
    vertical-align: middle;
  }

  &.compact {
    padding: 0;
    min-width: 0;
    max-width: 110px;
  }

  ${({ customStyles }) => customStyles}
`;

export const TableRow = styled.tr`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  transition: background-color 0.2s;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.action.active : 'transparent'};
  &:hover {
    background-color: ${({ theme }) => theme.colors.action.active};
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  `}
`;

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => rgba(theme.colors.background.default, 0.5)};
  svg {
    width: 30px;
    height: 30px;
    circle {
      stroke: ${({ theme }) => theme.colors.brand.light.base};
    }
  }
`;

export const Placeholder = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 15px;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.gray5};
`;

export const Info = styled.span`
  margin-right: 25px;
  margin-top: 4px;
  font-size: 12px;
  line-height: 14px;
  min-width: 72px;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const BottomPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;

  & ${Info} {
    margin-top: 0;
  }
`;
