import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as CheckIcon } from 'src/assets/icons/check_alt.svg';
import { ReactComponent as MinusIcon } from 'src/assets/icons/minus.svg';
import { ControlStyled, InputStyled, TextStyled, WrapperStyled } from './style';

const Checkbox = (props) => {
  const {
    id,
    label,
    checked,
    children,
    onChange,
    className,
    inputRef,
    indeterminate,
    ...rest
  } = props;
  return (
    <WrapperStyled htmlFor={id} className={className}>
      <InputStyled
        type="checkbox"
        id={id}
        checked={checked}
        indeterminate={indeterminate}
        onChange={onChange}
        ref={inputRef}
        {...rest}
      />
      {label && <span>{label}</span>}
      <ControlStyled>
        {indeterminate ? <MinusIcon /> : <CheckIcon />}
      </ControlStyled>
      <TextStyled>{children}</TextStyled>
    </WrapperStyled>
  );
};

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.any),
  ]),
  onChange: PropTypes.func,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  id: 'checkbox',
  label: '',
  className: 'base-checkbox',
  onChange: () => {},
  checked: false,
  children: '',
};

export default Checkbox;
