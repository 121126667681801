import PropTypes from 'prop-types';
import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import { ModalBodyStyled, ModalHeaderStyled, ModalStyled } from './style';

const Modal = ({
  isOpen,
  toggle,
  title,
  children,
  isStickyHeader,
  ...rest
}) => (
  <ModalStyled
    isOpen={isOpen}
    toggle={toggle}
    modalTransition={{ timeout: 0, ...rest.modalTransition }}
    backdropTransition={{ timeout: 0, ...rest.backdropTransition }}
    {...rest}
  >
    <ModalHeaderStyled isSticky={isStickyHeader}>
      {typeof title === 'string' ? <h5>{title}</h5> : title}
      <button className="close" onClick={toggle}>
        <CloseIcon />
      </button>
    </ModalHeaderStyled>
    <ModalBodyStyled>{children}</ModalBodyStyled>
  </ModalStyled>
);

Modal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.any,
  children: PropTypes.node,
  isStickyHeader: PropTypes.bool,
};

Modal.defaultProps = {
  isOpen: false,
  title: '',
  children: '',
  isStickyHeader: false,
  toggle: () => {},
};

export { ModalBody, ModalFooter };

export default Modal;
