import styled, { css } from 'styled-components';

export const AreaStyled = styled.div`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  position: relative;
  display: flex;
  flex-grow: 1;
  height: 100%;
  padding: 20px 20px 10px;
  background-color: ${({ theme }) =>
    theme.isDark
      ? theme.colors.background.secondary
      : theme.colors.border?.default};
  background-image: ${({ theme }) => `repeating-linear-gradient(
      to right,
      transparent,
      transparent 20px,
      ${theme.colors.background.variant3} 20px,
      ${theme.colors.background.variant3} 21px
    ),
    repeating-linear-gradient(
      to bottom,
      transparent,
      transparent 20px,
      ${theme.colors.background.variant3} 20px,
      ${theme.colors.background.variant3} 21px
    )`};
`;

export const OverlayLoaderStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;

  svg {
    width: 58px;
    height: 58px;
  }
`;

export const WrapperStyled = styled.div`
  display: inline-flex;
  max-width: 800px;
  width: 100%;
  margin: 36px auto;
  flex-direction: column;

  svg[data-tip='true'] {
    cursor: pointer;
    margin-left: 8px;
  }
`;

export const MessageStyled = styled.div`
  max-width: 540px;
  margin: 0 0 10px;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: red;
`;

export const TitleWrapperStyled = styled.div`
  position: relative;
`;

const inputStyles = css`
  min-width: 100px;
  min-height: 38px;
  padding: 4px 12px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: -0.59px;
  white-space: nowrap;
`;

export const ToggleEditorStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.brand.light.base};
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`;

export const TogglerButtonStyled = styled.span`
  position: absolute;
  top: 20px;
  right: 20px;
  svg {
    cursor: pointer;
    path {
      fill: ${({ theme }) => theme.colors.brand.light.base};
    }
  }
  .editor-hide {
    svg {
      cursor: pointer;
      path {
        fill: ${({ theme }) => theme.colors.danger.darkest};
      }
    }
  }
`;

export const OptionWrapperStyled = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 6px;
    display: block;
    fill: ${({ theme }) => theme.colors.text?.label};
    transform: translateY(-50%);
    user-select: none;
  }

  ${({ isValue }) =>
    isValue &&
    `
    padding-left: 14px;

    svg { left: 0; }
  `};
`;

export const SubTitleStyled = styled.h4`
  margin-top: 10px;
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  letter-spacing: -0.43;
  color: ${({ theme }) => theme.colors.text.variant3};
`;

export const TitleInputStyled = styled.input`
  ${inputStyles}
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
  width: ${({ titleWidth }) => titleWidth}px;
  border: none;
  outline: none;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.brand.light.base};
`;

export const SeperatorStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: -26px;
`;

export const ToolsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px;
`;

export const FormWrapperStyled = styled.fieldset`
  margin-top: 8px;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
`;

export const FromStyled = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

export const TimezoneSelectContainer = styled.div`
  background: rgba(0, 0, 0, 0.02);
  display: flex;
  padding: 8px;
  align-items: center;
  box-shadow: rgba(55, 53, 47, 0.1) 0px 0px 0px 1px;
  margin-bottom: 10px;
  position: relative;
  z-index: 2;

  & > p {
    font-size: 12px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text?.label};
    width: 80px;
    margin: 0;
    padding-right: 4px;
  }
  & > div {
    flex: 1;
    max-width: 300px;
  }
  & > svg {
    width: 16px;
    fill: ${({ theme }) => theme.colors.gray5};
    margin-left: 8px;
  }
`;

export const FiltersStyled = styled.div`
  .rule,
  .ruleGroup {
    position: relative;
  }

  .ruleGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-stretch;
    padding: 8px;
    background: rgba(0, 0, 0, 0.02);
    border-radius: 3px;
    box-shadow: rgba(55, 53, 47, 0.1) 0px 0px 0px 1px;
  }

  .ruleGroup-header:not(:only-child) {
    order: 1;
  }

  & > .ruleGroup {
    position: relative;
    padding-left: 0;
  }

  .ruleGroup .rule,
  .ruleGroup .ruleGroup {
    margin-bottom: 10px;
    margin-left: 80px;
  }

  .select__control,
  .rule > input,
  .rule-value > div,
  .rule-value > input {
    box-shadow: rgba(55, 53, 47, 0.1) 0px 0px 0px 1px;
    background: ${({ theme }) => theme.colors.background?.default};
    &:disabled {
      background-color: ${({ theme }) => theme.colors.gray2};
      cursor: not-allowed;
    }
  }

  /* dotted rule */
  .rule {
    display: flex;
    align-items: center;
  }

  .ruleGroup-header + .ruleGroup-body .rule::after,
  .ruleGroup-header + .rule::after,
  .ruleGroup-header + .ruleGroup::after {
    content: 'Where';
    position: absolute;
    left: -54px;
    font-size: 12px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text?.label};
  }

  .rule > *:not(:last-child) {
    margin: 0;
    margin-right: 10px;
  }

  .rule-value {
    min-width: 180px;
  }

  .rule-control {
    width: 180px;
  }

  .ruleGroup-combinators,
  .rule-operators {
    display: inline-flex;
    margin-right: 10px;

    & > div {
      width: 100%;
    }
  }

  .react-datepicker__input-container label {
    padding: 5px 8px;
    box-shadow: rgb(55 53 47 / 10%) 0px 0px 0px 1px;
    border: none;
    border-radius: 2px;
  }

  .react-datepicker__tab-loop {
    position: absolute;
  }

  .ruleGroup-combinators {
    position: absolute;
    top: 30px;
    align-self: flex-start;
    min-width: 70px !important;
    margin-top: -30px;

    .select__control {
      z-index: 1;
    }

    .select__menu {
      z-index: 5;
    }
  }

  .rule-operators {
    min-width: 86px !important;
  }

  .rule-fields {
    min-width: 160px !important;
  }

  .ruleGroup > .rule ~ .rule {
    top: auto;
  }

  .rule-remove,
  .ruleGroup-remove {
    font-size: 20px;
    font-weight: 300;
    padding: 8px 10px;
    vertical-align: bottom;
  }

  .rule-remove {
    margin-right: 0;
  }

  input.text-control,
  input.number-control {
    border: 1px solid ${({ theme }) => theme.colors.border?.default};
    border-radius: 4px;
    min-height: 38px;
    padding: 2px 8px;
    max-width: 180px;
    font-family: 'Inter', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    line-height: 24px;
    transition: all 100ms;

    &:focus {
      border-color: ${({ theme }) => theme.colors.brand.light.hover};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.brand.light.hover};
      outline: none;
    }
  }

  button:not(.react-datepicker__navigation),
  select {
    min-width: 30px;
    margin: 0;
    margin-right: 5px;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 14px;
    border: none;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.brand.light.base};
    color: ${({ theme }) => theme.colors.common.white};
  }

  button:not(.react-datepicker__navigation):hover,
  button:not(.react-datepicker__navigation):focus {
    outline: none;
    background-color: ${({ theme }) => theme.colors.brand.light.hover};
  }

  button:not(.react-datepicker__navigation):active {
    background-color: ${({ theme }) => theme.colors.brand.light.active};
  }

  select {
    height: 28px;
    background-color: ${({ theme }) => theme.colors.common.white};
    color: ${({ theme }) => theme.colors.gray6};
    border: 1px solid ${({ theme }) => theme.colors.border?.default};
  }
`;

export const CheckListStyled = styled.div`
  display: inline-flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  flex-wrap: wrap;

  label {
    margin: ${({ column }) => column && '0 0 10px 0'};
  }
`;

export const OffsetButtomStyled = styled.div`
  margin-bottom: 8px;
`;

export const NoOptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Logo = styled.img`
  margin-right: 10px;
`;
