import ConfigureFlowBlock from '../ConfigureFlowBlock';
import InputContainer from './Input/Input.container';

const ActionPlatformEditor = (props) => {
  return (
    <ConfigureFlowBlock title="Action" noSpace={true}>
      <InputContainer {...props} />
    </ConfigureFlowBlock>
  );
};

export default ActionPlatformEditor;
