import PropTypes from 'prop-types';
import React from 'react';
import Avatar from 'react-avatar';
import { animated } from 'react-spring';
import styled from 'styled-components';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/fi_check.svg';
import { avatarColors } from '../../config';
import { initialTabsNames } from '../config';
import More from './more';
import { ContentBox } from './styles';

const ThumbsIcons = {
  [initialTabsNames.STATUS]: () => <StatusImage>🔔</StatusImage>,
  [initialTabsNames.BOARDS]: () => <StatusImage>🔔</StatusImage>,
  [initialTabsNames.ASSIGN]: ({ name }) => (
    <Avatar
      color={Avatar.getRandomColor(name, avatarColors)}
      fgColor="#0000006e"
      textSizeRatio={2.5}
      name={name}
      size="30"
      round
    />
  ),
  [initialTabsNames.PRIORITY]: ({ color }) => (
    <PriorityImage>
      <PriorityColor color={color} />
    </PriorityImage>
  ),
  default: () => <StatusImage>🎉</StatusImage>,
};

const Common = ({
  item,
  isSelected,
  onSelect,
  hasOptions,
  activeTab,
  onChooseMore,
}) => {
  const Thumb = ThumbsIcons[activeTab] || ThumbsIcons.default;
  return (
    <Suggestion
      key={item.name}
      role="option"
      className={isSelected ? 'selected' : ''}
      onClick={hasOptions ? onChooseMore : onSelect}
    >
      <ContentBox hasOptions={hasOptions}>
        <Thumb {...item} />
        <MetaInfo>
          <span className="status-label">{item.name}</span>
          {isSelected && <CheckIcon />}
        </MetaInfo>
      </ContentBox>
      {hasOptions && <More />}
    </Suggestion>
  );
};

Common.propTypes = {
  item: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onChooseMore: PropTypes.func,
  isSelected: PropTypes.bool,
  hasOptions: PropTypes.bool,
};

Common.defaultProps = {
  isSelected: false,
  hasOptions: false,
  onSelect: () => {},
  onChooseMore: () => {},
};

export default Common;

const Suggestion = styled(animated.li)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 17px;
  height: 44px;
`;
const PriorityImage = styled.div`
  display: flex;
  min-width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 2px;
`;

const PriorityColor = styled.div`
  background-color: ${({ theme, color }) =>
    color
      ? theme.colors.priority?.[color] || color.toLowerCase()
      : theme.colors.priority?.default};
  width: 16px;
  height: 22px;
`;
const StatusImage = styled.div`
  display: flex;
  min-width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-radius: 2px;
  margin-right: 9px;
  .emoji {
    align-items: center;
    width: 16px;
  }
`;

const MetaInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  .status-label {
    font-size: 14px;
    color: #000000;
    line-height: 17px;
  }
`;
