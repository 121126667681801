import { Modal, ModalBody } from 'reactstrap';
import styled, { css } from 'styled-components';

export const ModalStyled = styled((props) => <Modal {...props} />)`
  border-radius: 4px;
`;

export const ModalHeaderStyled = styled.div`
  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 99;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    `}
  background-color: ${({ theme }) => theme.colors.background?.default};
  max-height: 82px;
  padding: 1rem 1rem;
  padding-right: 3rem;

  display: flex;
  max-width: 100%;
  word-break: break-all;
  min-height: 55px;
  flex-grow: 1;
  flex-shrink: 1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  button.close {
    position: absolute;
    top: 14px;
    right: 10px;
    border: none;
    background-color: transparent;
  }
`;

export const ModalBodyStyled = styled((props) => <ModalBody {...props} />)`
  padding: 0;
`;
