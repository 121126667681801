export default {
  colors: {
    accent: {
      main: '#051275',
      light: '#e1ecfe',
    },
    brand: {
      main: {
        base: '#005C55',
        hover: '#008270',
        active: '#007464'
      },
      light: {
        base: '#00BB99',
        hover: '#66D6C2',
        active: '#00BB99'
      },
      lighter: {
        hover: '#F0FBF9'
      }
    },
    background: {
      default: '#ffffff',
      secondary: '#faf9f6',
      dark: '#eeeeee',
      variant1: '#ffffff',
      variant2: '#efeeea',
      variant3: '#e9e9e9',
      variant4: '#faf9f6',
      variant5: '#e1eCfe',
      variant6: '#eef4fe',
      variant7: '#fbefc9',
    },
    text: {
      default: '#000000',
      main: '#051275',
      secondary: '#2c2d2f',
      dark: '#212121',
      label: '#616161',
      code: '#0086b3',
      variant1: '#212121',
      variant2: '#999999',
      variant3: '#666666',
      variant4: '#616999',
      variant5: '#7e7559',
    },
    border: {
      default: '#eeeeee',
      light: '#f8f7f7',
      active: '#051275',
      hover: '#dddddd',
      variant1: '#dddddd',
    },
    action: {
      hover: '#efeeea',
      active: '#eef4fe',
      disabled: '#999999',
      variant1: '#ffffff',
      variant2: '#f5f5f5',
      variant3: '#fff5e4',
    },
    danger: {
      main: '#e7121d',
      default: '#FF0000',
      light: '#fdeeee',
      dark: '#eb5757',
      darkest: '#c13515',
    },
    common: {
      white: '#ffffff',
      black: '#000000',
      contrast: '#000000',
    },
    // absolute colors
    gray1: '#dddddd',
    gray2: '#f5f5f5',
    gray3: '#efefef',
    gray4: '#c4c4c4',
    gray5: '#999999',
    gray6: '#666666',
    gray7: '#bdbdbd',
    lightGray1: '#efeeea',
    lightGray2: '#e9e9e9',
    blue1: '#061274',
    blue2: '#2366B3',
    blue3: '#616999',
    blue4: '#076AF4',
    blue5: '#0e1d96',
    blue6: '#1829B7',
    lightBlue: '#317fff',
    green1: '#6fcf97',
    green2: '#155724',
    green3: '#d4f2e9',
    green4: '#27ae60',
    orange1: '#f8a325',
    orange2: '#fcecD6',
    sazerac: '#fff5e4',
    red1: '#eb5757',
    red2: '#e7121d',
    red3: '#fdeeee',
    red4: '#ec0505',
    red5: '#ff0000',
    boyRed: '#756a4e',
    purple1: '#d6dbfc',
    yellow1: '#f2c94c',
    priority: {
      Red: '#e06464',
      Orange: '#e4980E',
      Yellow: '#ffc367',
      White: '#4575d3',
      Purple: 'transparent',
      default: '#e7e7e7',
    },
  },
  fonts: {
    main: 'Inter, "Open Sans", Helvetica Neue, sans-serif',
    title: '"SF Pro Display", Helvetica Neue, sans-serif',
    text: '"SF Pro Text","Helvetica Neue, sans-serif',
  },
  messenger: {
    default: {
      primaryColors: ['#051275', '#ecB940', '#89c5fc', '#9364b7'],
      logo: '/images/logo@x2.png',
      trayIcon: '/images/tray-icon.png',
      botName: 'Thread',
      botLogos: [
        '/images/bot-icon-1.png',
        '/images/bot-icon-2.png',
        '/images/bot-icon-3.png',
        '/images/bot-icon-4.png',
      ],
    },
  },
};
