import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useState } from 'react';
import Select from 'react-select/async-creatable';
import { useTheme } from 'styled-components';
import { debouncePromise } from '../../utils';
import {
  AvatarMultiValueLabel,
  AvatarOption,
  DropdownIndicator,
  GroupLabel,
  Menu,
} from './index';
import * as Styled from './style';
import { styles } from './style';

const SelectCreatable = ({
  title,
  size,
  value,
  customStyles,
  className,
  onChange,
  options,
  getOptionValue,
  getOptionLabel,
  formatGroupLabel,
  noOptionsMessage,
  getNewOptionData,
  components,
  onKeyDown,
  filterOption,
  isLoading,
  isDisabled,
  label,
  name,
  placeholder,
  selectRef,
  refreshDefaultOptions,
  onBlur,
  onFocus,
  onInputChange,
  inputValue,
  shouldControlInputValue,
  ...restProps
}) => {
  const theme = useTheme();
  const [isLoadingDefaultOptions, setIsLoadingDefaultOptions] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [selectInputValue, setSelectInputValue] = useState('');

  // after selecting option, react-select resets options to default
  // to prevent it latest options are stored in latestOptions (when refreshDefaultOptions: true)
  // https://github.com/JedWatson/react-select/issues/4012
  const [latestOptions, setLatestOptions] = useState([]);
  const defaultSelectOptions = refreshDefaultOptions
    ? latestOptions
    : defaultOptions;
  const promiseOptions = useCallback(
    debouncePromise(
      (...args) =>
        options(...args).then(data => {
          if (refreshDefaultOptions) {
            setLatestOptions(data);
          }
          return data;
        }),
      300
    ),
    [options]
  );

  const handleFocus = (...args) => {
    onFocus(...args);
    const label = getOptionLabel(value || {}) || '';
    handleInputChange(label);
    if (!defaultOptions.length) {
      setIsLoadingDefaultOptions(true);
      options().then(data => {
        setDefaultOptions(data);
        if (refreshDefaultOptions) {
          setLatestOptions(data);
        }
        setIsLoadingDefaultOptions(false);
      });
    }
  };
  const handleBlur = (...args) => {
    onBlur(...args);
    const label = getOptionLabel(value || {}) || '';
    setSelectInputValue(label);
    if (refreshDefaultOptions) {
      setLatestOptions(defaultOptions);
    }
  };
  const handleInputChange = newValue => {
    if (shouldControlInputValue && !onInputChange) {
      setSelectInputValue(newValue);
    }
  };

  return (
    <Fragment>
      {label && <Styled.Label htmlFor={name}>{label}</Styled.Label>}
      <Select
        className={className}
        allowCreateWhileLoading
        styles={styles({
          size,
          customStyles,
          shouldControlInputValue: shouldControlInputValue && !onInputChange,
        })}
        ref={selectRef}
        {...(onInputChange ||
          (shouldControlInputValue && {
            inputValue: onInputChange ? inputValue : selectInputValue,
          }))}
        onInputChange={onInputChange || handleInputChange}
        title={title}
        theme={theme}
        value={value}
        components={{
          ...components,
          DropdownIndicator: components?.DropdownIndicator || DropdownIndicator,
        }}
        getNewOptionData={getNewOptionData}
        name={name}
        id={name}
        cacheOptions
        defaultOptions={defaultSelectOptions}
        getOptionValue={getOptionValue}
        blurInputOnSelect
        getOptionLabel={getOptionLabel}
        onChange={(newValue, reason) => {
          const label = getOptionLabel(newValue || {}) || '';
          handleInputChange(label);
          onChange(newValue, reason);
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        loadOptions={promiseOptions}
        isLoading={isLoading || isLoadingDefaultOptions}
        isDisabled={isDisabled}
        onKeyDown={onKeyDown}
        filterOption={filterOption}
        noOptionsMessage={noOptionsMessage}
        {...(formatGroupLabel && {
          formatGroupLabel:
            formatGroupLabel === true ? GroupLabel : formatGroupLabel,
        })}
        placeholder={placeholder}
        {...restProps}
      />
    </Fragment>
  );
};

SelectCreatable.AvatarMultiValueLabel = AvatarMultiValueLabel;
SelectCreatable.AvatarOption = AvatarOption;
SelectCreatable.Menu = Menu;

SelectCreatable.propTypes = {
  value: PropTypes.any,
  title: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.func,
  size: PropTypes.string,
  onChange: PropTypes.func,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  formatGroupLabel: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  filterOption: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  components: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  refreshDefaultOptions: PropTypes.bool,
  shouldControlInputValue: PropTypes.bool,
};

SelectCreatable.defaultProps = {
  isDisabled: false,
  isLoading: false,
  formatGroupLabel: false,
  className: 'simple-select-async',
  components: {},
  label: null,
  name: null,
  placeholder: null,
  refreshDefaultOptions: false,
  onFocus: () => {},
  onBlur: () => {},
  getOptionLabel: opt => opt?.name,
  shouldControlInputValue: true,
};

export default SelectCreatable;
