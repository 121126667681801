import { ReactComponent as WaveIcon } from '../../../assets/icons/thready.svg';

export const initialTabsNames = {
  ROOT: 'ROOT',
  REPLY: 'REPLY',
  NOTE: 'NOTE',
  ASSIGN: 'ASSIGN',
  STATUS: 'STATUS',
  PRIORITY: 'PRIORITY',
  BOARDS: 'BOARDS',
  TIME: 'TIME',
  ACTION_PLATFORM: 'ACTION_PLATFORM',
  ACTIONS: 'ACTIONS',
};

export const excludedTabsList = [
  'board',
  'configuration',
  'type',
  'create time entry',
  'update ticket',
  'add note',
];

export const excludedFetchActionList = [
  initialTabsNames.NOTE,
  initialTabsNames.REPLY,
];

export const initialSuggestionsState = {
  actionID: 1,
  executedActionId: 1,
  showSelect: false,
  chain: true,
  action: null,
  payload: null,
  tab: initialTabsNames.REPLY,
  showSuggestions: false,
  tabSuggestions: [],
};
export const noFiltersExcludedList = ['REPLY', 'NOTE'];

export const initialTabs = {
  [initialTabsNames.REPLY]: {
    name: 'Reply',
    code: initialTabsNames.REPLY,
    clearInput: true,
    hasFilter: false,
    hasInlineCommand: false,
    sendText: 'Send',
  },
  [initialTabsNames.NOTE]: {
    name: 'Note',
    code: initialTabsNames.NOTE,
    clearInput: true,
    hasInlineCommand: false,
    hasFilter: false,
    sendText: 'Add note',
  },
  [initialTabsNames.ASSIGN]: {
    name: 'Assign',
    hasFilter: true,
    code: initialTabsNames.ASSIGN,
    prefixOnTabChange: true,
    hasInlineCommand: true,
    noChain: true,
  },
  [initialTabsNames.STATUS]: {
    name: 'Status',
    hasFilter: true,
    code: initialTabsNames.STATUS,
    prefixOnTabChange: true,
    hasInlineCommand: true,
    noChain: true,
  },
  [initialTabsNames.PRIORITY]: {
    name: 'Priority',
    code: initialTabsNames.PRIORITY,
    prefixOnTabChange: true,
    hasInlineCommand: true,
    hasFilter: true,
    noChain: true,
  },
  [initialTabsNames.TIME]: {
    name: 'Time Entry',
    code: initialTabsNames.TIME,
    prefixOnTabChange: true,
    hasInlineCommand: true,
    hasFilter: false,
  },
  [initialTabsNames.ACTION_PLATFORM]: {
    id: null,
    name: 'Actions',
    hasFilter: false,
    icon: <WaveIcon />,
    suggestionType: initialTabsNames.ACTION_PLATFORM,
    code: initialTabsNames.ACTION_PLATFORM,
  },
};

export const initialCommands = Object.keys(initialTabs).reduce(
  (commands, tabKey) => ({
    ...commands,
    [initialTabs[tabKey].command]: tabKey,
  }),
  {}
);

export const timerSuggestions = {
  start: [
    {
      name: 'Start Timer',
      info: 'The start time of the ticket',
      action: 'start',
    },
  ],
  stop: [
    {
      name: 'End Timer',
      action: 'stop',
      info: 'Stop the time working on the ticket',
    },
    {
      name: 'Clear Timer',
      action: 'clear',
      info: 'Clear the time working on the ticket',
    },
  ],
};

export const actionIcons = {
  ITGLUE: (
    <img
      src="https://chatgenie-inbox.s3.amazonaws.com/images/itglue-logo.png"
      alt="automate"
      width={18}
      height={18}
    />
  ),
  CONNECTWISE: (
    <img
      src="https://chatgenie-inbox.s3.amazonaws.com/images/manage.svg"
      alt="automate"
      width={20}
      height={20}
    />
  ),
  CHATGENIE_API: (
    <img
      src="https://chatgenie-inbox.s3.amazonaws.com/images/chatgenie-logo.png"
      alt="automate"
      width={18}
      height={18}
    />
  ),
  AUTOMATE: (
    <img
      src="https://chatgenie-inbox.s3.amazonaws.com/images/automate-logo.png"
      alt="automate"
      width={18}
      height={18}
    />
  ),
};

export const actionHandlers = {
  'Action.OpenUrl': ({ url }) => {
    window.open(url, '_blank').focus();
  },
};
