import React from 'react';
import PropTypes from 'prop-types';

import SimpleSelect from 'src/components/Select';

const CustomSelect = ({
  title,
  value,
  options,
  handleOnChange,
  className,
  context: { isSearchable },
}) => {
  return (
    <SimpleSelect
      className={className
        .split(' ')
        .filter(item => item !== 'form-control')
        .join(' ')}
      title={title}
      name="field"
      size="small"
      isClearable={false}
      isSearchable={title === 'Fields' ? true : isSearchable}
      value={options.filter(option => option.name === value)[0]}
      onChange={value => handleOnChange(value.name)}
      getOptionValue={option => option.name}
      getOptionLabel={option => option.label}
      options={options}
    />
  );
};

CustomSelect.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any),
  handleOnChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isSearchable: PropTypes.bool,
};

CustomSelect.defaultProps = {
  value: '',
  className: '',
  options: [],
  isSearchable: true,
};

export default CustomSelect;
