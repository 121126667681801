import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import ConfigureFlowBlock from '../ConfigureFlowBlock';
import Select from 'src/components/Select';
import { MessageStyled, SubTitleStyled } from '../style';
import * as Styled from './style';

const ActionEditor = ({ errorMessage, provider, onChange, getConnections, isFetching }) => {
  const [activeAction, setActiveAction] = useState(provider);
  const promiseOptions = (like) =>
    getConnections({ queryString: `?filter-name=~${like || ''}` }).then((res) => {
      const { data } = res.action.payload.data;
      return data;
    });

  useEffect(() => {
    if (activeAction) {
      onChange(activeAction);
    }
  }, [activeAction]);

  return (
    <ConfigureFlowBlock
      title="Action"
      icon={
        <Styled.ActionFigure src={activeAction?.settings?.image?.path} alt={activeAction?.name} />
      }
      isLoading={isFetching}
    >
      <MessageStyled>{errorMessage}</MessageStyled>
      <SubTitleStyled>Create provider ticket</SubTitleStyled>
      <Select
        name="connection"
        title="connection"
        getOptionValue={(option) => option.name}
        value={activeAction}
        getOptionLabel={(option) => option.name}
        options={[]}
        onChange={(option) => setActiveAction(option)}
      />
    </ConfigureFlowBlock>
  );
};

ActionEditor.propTypes = {
  errorMessage: PropTypes.string,
  provider: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  getConnections: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

ActionEditor.defaultProps = {
  errorMessage: '',
  provider: null,
  isFetching: false,
  getConnections: () => {},
};

export default ActionEditor;
