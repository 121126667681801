import moment from 'moment';
import PropTypes from 'prop-types';
import 'rc-time-picker/assets/index.css';
import React from 'react';
import { SelectAsync } from 'src';
import { SelectAsyncCreatable } from 'src';
import Input from 'src/components/Input';
import TimePicker from 'src/components/TimePicker';
import DatePicker from 'src/components/DatePicker';
import { filterForm } from 'src/constants/flows';

const ValueEditor = ({
  field,
  operator,
  value,
  handleOnChange,
  title,
  className,
  type,
  inputType,
  values,
  context,
}) => {
  if (operator === 'null' || operator === 'notNull') {
    return null;
  }
  const { searchAttributeValues, emptyValueRequiredOperators } = context;

  if (emptyValueRequiredOperators.includes(operator)) {
    return (
      <Input
        className="rule-control"
        type={'text'}
        value={''}
        title={title}
        size="small"
        disabled
      />
    );
  }
 

  switch (type) {
    case 'react-select-creatable': {
      const promiseOptions = newValue =>
        searchAttributeValues(field, newValue).then((data = []) => {
          const options = Array.isArray(data)
            ? data
            : Object.keys(data).map(key => ({
                label: key,
                options: data[key],
              }));

          if (value === 'firstOne') {
            handleOnChange({ ...data[0], type: 'object' });
          }

          return options;
        });

      return (
        <SelectAsyncCreatable
          key={field}
          field={field}
          title={title}
          size="small"
          options={promiseOptions}
          className={className}
          value={value}
          formatGroupLabel
          getOptionLabel={option => option.isNew ? `Add "${option.displayValue}"` : option.displayValue}
          onChange={input => {
            handleOnChange({ displayValue: input.displayValue, value: input.value, type: 'matching_object' })
          }}
          getNewOptionData={(value, label) => ({ displayValue: value, isNew: true, value: value })}
          noOptionsMessage={event =>
            event.inputValue
              ? filterForm.ATTR_VALUE_NOT_FOUND
              : filterForm.START_SEARCH_ATTR_VALUE
          }
        />
      );
    }
    case 'react-select': {
      const promiseOptions = newValue =>
        searchAttributeValues(field, newValue).then((data = []) => {
          const options = Array.isArray(data)
            ? data
            : Object.keys(data).map(key => ({
                label: key,
                options: data[key],
              }));

          if (value === 'firstOne') {
            handleOnChange({ ...data[0], type: 'object' });
          }

          return options;
        });

      return (
        <SelectAsync
          key={field}
          field={field}
          title={title}
          size="small"
          options={promiseOptions}
          className={className}
          value={value}
          formatGroupLabel
          getOptionLabel={option => option.displayValue}
          onChange={newValue => handleOnChange({ ...newValue, type: 'object' })}
          noOptionsMessage={event =>
            event.inputValue
              ? filterForm.ATTR_VALUE_NOT_FOUND
              : filterForm.START_SEARCH_ATTR_VALUE
          }
        />
      );
    }
    case 'react-select-week': {
      const promiseOptions = newValue =>
        searchAttributeValues(field, newValue).then(data => {
          if (value === 'firstOne')
            handleOnChange({ ...data[0], type: 'day_of_week' });
          return data;
        });

      return (
        <SelectAsync
          key={field}
          field={field}
          title={title}
          size="small"
          options={promiseOptions}
          className={className}
          value={value}
          getOptionLabel={option => option.displayValue}
          onChange={newValue =>
            handleOnChange({ ...newValue, type: 'day_of_week' })
          }
          noOptionsMessage={event =>
            event.inputValue
              ? filterForm.ATTR_VALUE_NOT_FOUND
              : filterForm.START_SEARCH_ATTR_VALUE
          }
        />
      );
    }
    case 'select':
      return (
        <select
          className={className}
          title={title}
          onChange={event => handleOnChange(event.target.value)}
          value={value}
        >
          {values.map(v => (
            <option key={v.name} value={v.name}>
              {v.label}
            </option>
          ))}
        </select>
      );

    case 'checkbox':
      return (
        <input
          type="checkbox"
          className={className}
          title={title}
          onChange={event => handleOnChange(event.target.checked)}
          checked={!!value}
        />
      );

    case 'time': {
      const format = 'hh:mm A';

      if (!value.value) {
        handleOnChange({ value: '12:00 AM', type: 'time_of_day' });
      }

      return (
        <TimePicker
          showSecond={false}
          key={value.value}
          defaultValue={moment(value.value, format)}
          className={className}
          onChange={value =>
            handleOnChange({
              value: value && value.format(format),
              type: 'time_of_day',
            })
          }
          format={format}
          use12Hours
        />
      );
    }


    case 'date': {
      const format = 'yyyy-MM-DD';

      if (!value.value) {
        handleOnChange({ type: 'date', value: moment(new Date()).format(format) });
      }

      return (
        <DatePicker
          onChange={date => handleOnChange({ type: 'date', value: moment(date).format(format) })}
          selected={value.value && moment(value.value, format).toDate()}
          dateFormat={'yyyy-MM-dd'}
        />
      );
    }

    case 'radio':
      return (
        <span className={className} title={title}>
          {values.map(v => (
            <label key={v.name} htmlFor={v.name}>
              <input
                id={v.name}
                type="radio"
                value={v.name}
                checked={value === v.name}
                onChange={event => handleOnChange(event.target.value)}
              />
              {v.label}
            </label>
          ))}
        </span>
      );

    case 'react-input':
      return (
        <Input
          className="rule-control"
          type={inputType || 'text'}
          value={value.value || ''}
          title={title}
          size="small"
          onChange={event =>
            handleOnChange({
              value: event.target.value,
              type: inputType === 'number' ? inputType : 'string',
            })
          }
        />
      );

    default:
      return (
        <Input
          className="rule-control"
          type="text"
          size="small"
          value={typeof value === 'string' ? value : ''}
          title={title}
          onChange={event => handleOnChange(event.target.value)}
        />
      );
  }
};

ValueEditor.propTypes = {
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  operator: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  handleOnChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'react-select',
    'react-input',
    'select',
    'checkbox',
    'radio',
    'text',
  ]).isRequired,
  inputType: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchAttributeValues: PropTypes.func.isRequired,
};

ValueEditor.defaultProps = {
  searchAttributeValues: () => {},
};

export default React.memo(ValueEditor);
