import styled from 'styled-components';

export const SelectedItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .picker-item-name {
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
  }
  .picker-item-email {
    font-size: 10px;
    line-height: 12px;
  }
  div[role='button'] {
    display: flex;
    color: ${({ theme }) => theme.colors.gray5};
    margin-left: 10px;
  }
  .picker-item-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
  }
`;
