import { components } from 'react-select';
import AsyncSelect from 'src/components/Select/async';
import { initialTabsNames } from '../config';
import { useAnimate } from '../hook/useAnimate';
import Action from './Action';
import ActionPlatform from './ActionPlatform';
import Common from './Common';
import Root from './Root';
import Skeleton from './skeleton';
import * as Styled from './styles';
import Time from './Time';

const SuggestionRenderers = {
  [initialTabsNames.TIME]: Time,
  [initialTabsNames.ACTION_PLATFORM]: ActionPlatform,
  [initialTabsNames.STATUS]: Common,
  [initialTabsNames.ASSIGN]: Common,
  [initialTabsNames.PRIORITY]: Common,
  [initialTabsNames.BOARDS]: Common,
  [initialTabsNames.ROOT]: Root,
  [initialTabsNames.REPLY]: Action,
  default: ActionPlatform,
};

const titleRender = {
  [initialTabsNames.ROOT]: 'Suggestions',
  [initialTabsNames.ACTION_PLATFORM]: 'Browse by App',
};

const DefaultSuggestion = ({ isSelected, item, onSelect }) => (
  <Styled.Suggestion
    key={item.name}
    className={isSelected ? 'selected' : ''}
    onClick={onSelect}
  >
    {item.name}
  </Styled.Suggestion>
);

const NoSuggestions = () => {
  const { contentProps } = useAnimate({ type: 'fade' });
  return (
    <Styled.NoSuggestions style={contentProps}>
      <i>No suggestions found</i>
    </Styled.NoSuggestions>
  );
};

const Suggestions = ({
  suggestions = [],
  selectedIndex = 0,
  onSelect = () => {},
  activeTab,
  suggestionType,
  isLoading,
  currentAction,
  isSelectable,
  tabs,
  getOptions,
  handleChange,
  suggestionId,
  suggestionPayload,
  hasBreadcrumb,
  showSuggestions,
  breadcrumb,
  showSelect,
}) => {
  const { is_chainable, form_data, payload, selectedOptionId, actions } =
    currentAction;
  const showNoSuggestions =
    !isLoading &&
    isSelectable &&
    !hasSelectedOption &&
    suggestions.length === 0;
  const lastBreadcrumbItem = breadcrumb[breadcrumb.length - 1];
  const parentActionId =
    lastBreadcrumbItem?.id || tabs[activeTab]?.id || suggestionId;

  const placeholderName = hasBreadcrumb
    ? lastBreadcrumbItem?.name?.toLowerCase()
    : tabs[activeTab]?.name?.toLowerCase();
  const placeholder = 'Select a ' + placeholderName || 'action';
  const hasSelectedOption =
    parentActionId === payload?.actions?.selected_action_id;

  const SuggestionComponent = SuggestionRenderers?.[activeTab || 'default'];

  const customStyles = {
    valueContainer: (state) => ({
      padding: '0',
    }),
    option: (state) => ({
      padding: '0',
      backgroundColor: 'transparent',
    }),
    placeholder: (state) => ({
      padding: '0 14px',
    }),
    input: (state) => ({
      padding: '0 14px',
    }),
    menu: (state) => ({
      position: 'relative',
      display: 'block',
    }),
    menuList: (state) => ({
      maxHeight: 200,
    }),
  };

  const filterOptions = (query) =>
    getOptions({
      ...suggestionPayload,
      params: { ...suggestionPayload.params, query },
    });

  const SelectOption = (option) => {
    const isSelected = option?.form_data?.id === selectedOptionId;
    const code = option.code || suggestionPayload?.name;

    return (
      <SuggestionComponent
        item={option?.form_data}
        isSelected={isSelected}
        activeTab={activeTab}
        code={code}
        fromSelect={true}
      />
    );
  };

  const SingleValue = ({ children, selectProps, ...props }) => {
    const { data } = props;
    if (!data) return children;
    const code = currentAction.code || suggestionPayload?.name;
    return (
      <components.SingleValue {...props}>
        <SuggestionComponent
          item={data.form_data}
          isSelected={false}
          activeTab={activeTab}
          code={code}
          fromSelect={true}
        />
      </components.SingleValue>
    );
  };

  const LoadingMessage = (props) => {
    return (
      <Styled.SuggestionsList {...props.innerProps}>
        {new Array(10).fill(0, 0).map((_, idx) => (
          <li style={{ height: 45 }} key={idx}>
            <Skeleton />
          </li>
        ))}
      </Styled.SuggestionsList>
    );
  };
  return (
    <Styled.SuggestionsContainer>
      {showSelect && (
        <Styled.SelectBox>
          <AsyncSelect
            name="select_action"
            value={hasSelectedOption && form_data?.id ? payload : null}
            placeholder={placeholder}
            shouldControlInputValue={false}
            cacheOptions={false}
            options={(query) => filterOptions(query)}
            onChange={handleChange}
            formatOptionLabel={(item) => SelectOption(item)}
            refreshDefaultOptions={true}
            components={{ SingleValue, LoadingMessage }}
            customStyles={customStyles}
            defaultMenuIsOpen={!hasSelectedOption}
            menuShouldScrollIntoView={false}
            defaultOptions={true}
            key={activeTab + suggestionId}
          />
        </Styled.SelectBox>
      )}

      {showSuggestions && (
        <Styled.SuggestionsList
          id="suggestions-list"
          hasContent={suggestions.length > 0}
        >
          {isLoading &&
            new Array(10).fill(0, 0).map((_, idx) => (
              <li style={{ height: 45 }}>
                <Skeleton />
              </li>
            ))}
          {suggestions.length > 0
            ? suggestions.map((item, idx) => {
                if (!item) return;
                const hasOptions =
                  item.is_chainable || item.actions?.length > 0;
                const isSelected = item?.id === form_data?.id;
                const code = item.code || suggestionPayload?.name;
                return (
                  <SuggestionComponent
                    key={idx + item.name}
                    item={{ ...item, code }}
                    isSelected={!hasOptions && isSelected}
                    index={idx}
                    onChooseMore={() => onSelect(idx, hasOptions)}
                    onSelect={() => onSelect(idx, false)}
                    activeTab={activeTab}
                    hasOptions={hasOptions}
                  />
                );
              })
            : showNoSuggestions && <NoSuggestions />}
        </Styled.SuggestionsList>
      )}
    </Styled.SuggestionsContainer>
  );
};

export default Suggestions;
