import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import * as Styled from './styles';

const TableBodyDND = ({
  getTableBodyProps,
  rows,
  prepareRow,
  clickRowHandler,
  activeRowId,
  handleDragEnd,
}) => (
  <DragDropContext onDragEnd={handleDragEnd}>
    <Droppable droppableId="table-body">
      {provided => (
        <tbody
          ref={provided.innerRef}
          {...provided.droppableProps}
          {...getTableBodyProps()}
        >
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <Draggable
                draggableId={row.original.id}
                key={row.original.id}
                index={row.index}
              >
                {(provided, snapshot) => {
                  return (
                    <Styled.TableRow
                      {...row.getRowProps()}
                      onClick={() => clickRowHandler(row)}
                      disabled={row.disabled}
                      active={Number(activeRowId) === row.id}
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                    >
                      {row.cells.map(cell => (
                        <Styled.TableCell
                          {...cell.getCellProps({
                            style: {
                              minWidth: cell.column.minWidth,
                              width: cell.column.width,
                            },
                          })}
                          customStyles={cell.column.customStyles || {}}
                        >
                          {cell.render('Cell', {
                            dragHandleProps: provided.dragHandleProps,
                            isSomethingDragging: snapshot.isDraggingOver,
                            isDragging: snapshot.isDragging,
                          })}
                        </Styled.TableCell>
                      ))}
                    </Styled.TableRow>
                  );
                }}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  </DragDropContext>
);

export default TableBodyDND;
