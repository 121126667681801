import styled, { createGlobalStyle } from 'styled-components/macro';
import { rgba } from 'polished';

export const GlobalDatePickerStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    width: 100%;
    input {
      &:focus {
        background: ${({ theme }) => theme.colors.action.variant1};
        border-color:  ${({ theme }) => theme.colors.border.active};
      }
      &:hover:not(:focus) {
        background: ${({ theme }) => theme.colors.action.variant1};
        border-color:  ${({ theme }) => theme.colors.border.default};
      }
    }
  }
`;

export const Container = styled.div`
  margin-top: -10px;

  .react-datepicker {
    border-radius: 2px;
    box-shadow: 0px 4px 4px ${({ theme }) => rgba(theme.colors.text.default, 0.25)};
    background: ${({ theme }) => theme.colors.background.default};
    border-color: ${({ theme }) => theme.colors.border.default};
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.background.default};
    border-color: ${({ theme }) => theme.colors.border.default};
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: ${({ theme }) => theme.colors.text.default};
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    color: ${({ theme }) => theme.colors.text.default};
  }
  .react-datepicker__day:not(.react-datepicker__day--selected) {
    color: ${({ theme }) => theme.colors.text.default};
    &:hover {
      background: ${({ theme }) => theme.colors.action.hover};
    }
  }
  .react-datepicker__day--selected {
    color: ${({ theme }) => theme.colors.common.white};
  }
`;

export const Control = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-radius: 4px;
  padding: 8px 12px;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.background.default};

  svg {
    width: 16px;
    height: 16px;
    stroke: ${({ theme }) => theme.colors.text.variant2};
  }
`;

export const Input = styled.input`
  font-size: 14px;
  height: 100%;
  border: none;
  background-color: transparent;
  padding: 0;
`;
