import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import styled, { css } from 'styled-components/macro';

export const SpinnerStyled = styled(Spinner)`
  width: 16px;
  height: 16px;

  circle {
    stroke: ${({ theme }) => theme.colors.common.white};
  }
`;

const styles = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'inline-flex')};
  align-items: center;
  justify-content: center;
  text-decoration: none;
  min-width: 136px;
  height: 32px;
  padding: 0 10px;
  border: 0 none;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.brand.light.base};
  color: ${({ theme }) => theme.colors.common.white};
  white-space: nowrap;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.common.white};
    text-decoration: none;
    background-color: ${({ theme }) => theme.colors.brand.light.hover};
  }

  &:active {
    color: ${({ theme }) => theme.colors.common.white};
    background-color: ${({ theme }) => theme.colors.brand.light.active};
  }

  ${({ full }) => full && 'width: 100%;'}

  ${({ size }) =>
    size === 'small' &&
    `
    height: 30px;
    min-width: 58px;
  `}

  ${({ size }) =>
    size === 'large' &&
    `
    height: 44px;
    min-width: 165px;
    font-weight: 700;

    ${SpinnerStyled} {
      width: 20px;
      height: 20px;
    }
  `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.gray5};
      cursor: not-allowed;

      &:hover,
      &:focus {
        outline: none;
        background-color: ${({ theme }) => theme.colors.gray5};
      }
    `}

  ${({ styleMode, disabled }) =>
    styleMode === 'outline' &&
    css`
      background-color: ${({ theme }) => theme.colors.common.white};
      color: ${({ theme }) => theme.colors.brand.light.base};
      border: 1px solid ${({ theme }) => theme.colors.brand.light.base};

      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors.common.white};
        background-color: ${({ theme }) => theme.colors.brand.light.hover};
      }

      &:active {
        color: ${({ theme }) => theme.colors.common.white};
        background-color: ${({ theme }) => theme.colors.brand.light.active};
      }

      ${SpinnerStyled} {
        circle {
          stroke: ${({ theme }) => theme.colors.gray5};
        }
      }

      ${disabled &&
      css`
        background-color: ${({ theme }) => theme.colors.common.white};
        color: ${({ theme }) => theme.colors.gray5};
        border: 1px solid ${({ theme }) => theme.colors.gray5};

        &:hover,
        &:focus {
          color: ${({ theme }) => theme.colors.gray5};
          background-color: ${({ theme }) => theme.colors.common.white};
        }
      `}
    `}

  ${({ styleMode, disabled }) =>
    styleMode === 'danger' &&
    css`
      background-color: ${({ theme }) => theme.colors.red4};

      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors.brand.light.hover};
        background-color: ${({ theme }) => theme.colors.red5};
      }

      &:active {
        background-color: ${({ theme }) => theme.colors.red4};
      }

      ${disabled &&
      css`
        background-color: ${({ theme }) => theme.colors.gray5};
        cursor: not-allowed;

        &:hover,
        &:focus {
          outline: none;
          background-color: ${({ theme }) => theme.colors.gray5};
        }
      `}
    `}

  ${({ customStyle }) => customStyle}
`;

export const ButtonStyled = styled.button`
  ${styles}
`;

export const LinkStyled = styled.a`
  ${styles}
`;

export const IconContainer = styled.span`
  margin-right: 5px;
`;
