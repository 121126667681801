/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { components } from 'react-select';
import { ReactComponent as Hash } from 'src/assets/icons/hash.svg';
import { ReactComponent as Info } from 'src/assets/icons/info.svg';
import { ReactComponent as Lock } from 'src/assets/icons/lock.svg';
import { ReactComponent as Plus } from 'src/assets/icons/plus.svg';
import { ReactComponent as SlackLogo } from 'src/assets/icons/slack.svg';
import { ReactComponent as TeamsLogo } from 'src/assets/icons/teams.svg';
import { ReactComponent as Trash } from 'src/assets/icons/trash.svg';
import { ReactComponent as User } from 'src/assets/icons/user.svg';
import SimpleButton from 'src/components/Button';
import AsyncSelect from 'src/components/Select/async';
import { channelForm } from 'src/constants/flows';
import ConfigureFlowBlock from '../ConfigureFlowBlock';
import {
  CheckListStyled,
  FormWrapperStyled,
  MessageStyled,
  NoOptionsWrapper,
  OffsetButtomStyled,
  OptionWrapperStyled,
  SubTitleStyled,
  ToggleEditorStyled,
  TogglerButtonStyled,
} from '../style';

const ChannelEditor = (props) => {
  const {
    chatConfiguration,
    getChannels,
    getCategories,
    saveChannel,
    savingError,
    error,
    info,
    channelData,
    categoryData,
    onChangeChannel,
    onChangeCategory,
    flowChannelError,
    setFlowChannelError,
    children,
  } = props;

  const [isSavingLoader, setIsSavingLoader] = useState();

  const channelIconTypes = {
    private: <Lock width="12px" height="12px" />,
    public: <Hash width="10px" height="10px" />,
    user: <User width="12px" height="10px" />,
  };

  const SingleValue = ({ children, data, ...props }) => (
    <components.SingleValue {...props}>
      <OptionWrapperStyled isValue>
        {channelIconTypes[data.type]}
        {children}
      </OptionWrapperStyled>
    </components.SingleValue>
  );

  const Option = ({ data, ...props }) => (
    <OptionWrapperStyled>
      {channelIconTypes[data.type]}
      <components.Option {...props} />
    </OptionWrapperStyled>
  );
  const [isButtonAddChannel, setIsButtonAddChannel] = useState(true);
  const [savingChannelError, setSavingChannelError] = useState('');
  const [isHideContent, setIsHideContent] = useState(false);

  const toggleEditorTitle =
    chatConfiguration === 'SLACK' ? 'Add Slack' : 'Add Microsoft Teams';
  useEffect(() => {
    setSavingChannelError(savingError);
  }, [savingError]);

  useEffect(() => {
    if (isHideContent) {
      onChangeChannel(null);
    }
  }, [isHideContent]);

  useEffect(() => {
    if (channelData && categoryData?.id) {
      setIsHideContent(false)
    }
  }, [channelData]);

  const promiseOptionsCategories = (like) =>
    getCategories(like).then((data = []) => {
      return data.map((item) => ({
        value: item.category_id,
        name: item.category,
      }));
    });

  const promiseOptionsChannels = (like) =>
    getChannels(
      like,
      chatConfiguration.toLowerCase(),
      'public',
      categoryData?.id
    ).then((data = []) => {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        ...item,
      }));
    });

  const addNewChannel = (name) => {
    setSavingChannelError('');
    setIsButtonAddChannel(false);
    onChangeChannel({ id: null, name, type: 'public' });
  };

  const saveChannelHandler = () => {
    const data = {
      friendly_name: channelData.name,
      platform: chatConfiguration.toLowerCase(),
      type: 'channel',
      ...(chatConfiguration === 'MS_TEAMS' && { categoryId: categoryData.id })
    };

    setIsSavingLoader(true)
    saveChannel(data).then((res) => {
      setIsSavingLoader(false)
      onChangeChannel(res)
    }).catch(() => {
      setIsSavingLoader(false)
    });
  };

  const getChannelFilterOption = (option) =>
    option.data.category_id === categoryData.id;
  const isShowButtonNewChannel = (event) =>
    (isButtonAddChannel && event.inputValue) ||
    (channelData && channelData.name !== event.inputValue);

  const handleCloseChannel = () => {
    setIsHideContent(true);
    onChangeCategory({ id: null, name: null });
    setFlowChannelError('');
  };

  const TogglerIcon = () =>
    isHideContent ? (
      <Plus onClick={() => setIsHideContent(false)} />
    ) : (
      <span className="editor-hide">
        <Trash onClick={handleCloseChannel} />
      </span>
    );

  return (
    <ConfigureFlowBlock
      className="channel-editor"
      icon={
        chatConfiguration === 'SLACK' ? (
          <SlackLogo width="26" />
        ) : (
          <TeamsLogo width="26" />
        )
      }
    >
      <TogglerButtonStyled>
        <TogglerIcon />
      </TogglerButtonStyled>
      {isHideContent ? (
        <ToggleEditorStyled>{toggleEditorTitle}</ToggleEditorStyled>
      ) : (
        <>
          {error && !savingChannelError && (
            <MessageStyled>{error}</MessageStyled>
          )}
          {info && (
            <MessageStyled typeMsg="info" typeView="block" icon={<Info />}>
              <span dangerouslySetInnerHTML={{ __html: info }} />
            </MessageStyled>
          )}
          {savingChannelError && (
            <MessageStyled>{savingChannelError}</MessageStyled>
          )}
          {flowChannelError && (
            <MessageStyled>{flowChannelError}</MessageStyled>
          )}
          {chatConfiguration === 'MS_TEAMS' && (
            <Fragment>
              <SubTitleStyled>Team </SubTitleStyled>
              <OffsetButtomStyled>
                <AsyncSelect
                  name="team"
                  title="teams"
                  value={categoryData?.id ? categoryData : null}
                  placeholder="Start typing team name"
                  getOptionValue={(option) => option.name}
                  getOptionLabel={(option) => option.name}
                  options={promiseOptionsCategories}
                  onChange={onChangeCategory}
                />
              </OffsetButtomStyled>
            </Fragment>
          )}
          <SubTitleStyled>Channel</SubTitleStyled>
          <OffsetButtomStyled>
            <AsyncSelect
              key={categoryData?.id}
              name="channel"
              title="channels"
              value={channelData}
              placeholder="Start typing channel name"
              components={{ Option, SingleValue }}
              getOptionValue={(option) => option.name}
              getOptionLabel={(option) => option.name}
              onChange={onChangeChannel}
              isDisabled={chatConfiguration === 'MS_TEAMS' && !categoryData?.id}
              options={promiseOptionsChannels}
              onKeyDown={() => setIsButtonAddChannel(true)}
              filterOption={
                chatConfiguration === 'MS_TEAMS' && getChannelFilterOption
              }
              noOptionsMessage={(event) =>
                isShowButtonNewChannel(event) ? (
                  <NoOptionsWrapper>
                    <OffsetButtomStyled>
                      {channelForm.CHANNELS_NOT_FOUND}
                    </OffsetButtomStyled>
                    <SimpleButton
                      onClick={() => addNewChannel(event.inputValue)}
                      size="small"
                      isLoader={isSavingLoader}
                      isDisabled={!event.inputValue}
                    >
                      Create a new channel &#8220;{event.inputValue}&#8221;
                    </SimpleButton>
                  </NoOptionsWrapper>
                ) : null
              }
            />
          </OffsetButtomStyled>
          {channelData && channelData.id === null && (
            <SimpleButton
              className="add-channel"
              onClick={saveChannelHandler}
              size="small"
              isLoader={isSavingLoader}
              style={{ marginBottom: 10 }}
            >
              Add channel
            </SimpleButton>
          )}
          <FormWrapperStyled>
            <CheckListStyled column>{children}</CheckListStyled>
          </FormWrapperStyled>
        </>
      )}
    </ConfigureFlowBlock>
  );
};

ChannelEditor.propTypes = {
  chatConfiguration: PropTypes.string.isRequired,
  getChannels: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  error: PropTypes.string,
  info: PropTypes.any,
  channelData: PropTypes.objectOf(PropTypes.any),
  categoryData: PropTypes.objectOf(PropTypes.any),
  onChangeChannel: PropTypes.func.isRequired,
  onChangeCategory: PropTypes.func.isRequired,
  saveChannel: PropTypes.func.isRequired,
  savingLoader: PropTypes.bool,
  savingError: PropTypes.string,
  children: PropTypes.node,
};

ChannelEditor.defaultProps = {
  channelData: {},
  categoryData: null,
  error: '',
  info: '',
  children: '',
  getChannels: (like, category) => Promise.resolve([]),
  getCategories: (like) => {},
  saveChannel: (data) => {},
  chatConfiguration: null,
  channelList: null,
  savingLoader: false,
  savingError: '',
};

export default ChannelEditor;
