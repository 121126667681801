import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker, { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FiCalendar } from 'react-icons/fi';
import * as Styled from './styles';

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <Styled.Control>
    <Styled.Input type="button" onClick={onClick} ref={ref} value={value} />
    <FiCalendar />
  </Styled.Control>
));

const CustomContainer = ({ className, children }) => (
  <Styled.Container>
    <CalendarContainer className={className}>{children}</CalendarContainer>
  </Styled.Container>
);

const DatePicker = props => (
  <>
    <Styled.GlobalDatePickerStyle />
    <ReactDatePicker
      popperClassName="some-custom-class"
      customInput={<CustomInput />}
      calendarContainer={CustomContainer}
      {...props}
    />
  </>
);

DatePicker.propTypes = {
  onChange: PropTypes.func,
  timeInputLabel: PropTypes.string,
  dateFormat: PropTypes.string
};

DatePicker.defaultProps = {
  onChange: () => {},
  timeInputLabel: "Time:",
  dateFormat: 'MM/dd/yyyy - h:mm aa'
};

export default DatePicker;
