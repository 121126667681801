import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }

  input:checked + span::after {
    opacity: 1;
  }
  input:checked + span::before {
    border: 1px solid ${({ theme }) => theme.colors.brand.light.base};
    background-color: ${({ theme }) => theme.colors.brand.light.base};
  }
`;

export const Control = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;

  &::after,
  &::before {
    content: '';
    display: block;
    position: relative;
    top: 0;
    flex-shrink: 0;
    border-radius: 50%;
    transition: all 0.15s;
    pointer-events: none;
  }

  &::before {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.colors.gray1};
    background-color: ${({ theme }) => theme.colors.lightGray1};
    box-shadow: 0 0 0 2px transparent;
    cursor: pointer;
    transition: all 0.15s;
  }

  &::after {
    position: absolute;
    left: 0;
    width: 8px;
    height: 8px;
    margin: 4px;
    opacity: 0;
    background-color: ${({ theme }) => theme.colors.common.white};
  }
`;
