import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const Tooltip = styled(ReactTooltip)`
  &&&& {
    padding: 12px 17px;
    border-radius: 2px;
    background: rgba(0, 187, 153, 0.9);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.common.white};

    a {
      color: ${({ theme }) => theme.colors.common.white};
      text-decoration: underline;

      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors.common.white};
      }
    }

    &.place-top::after {
      border-top-color: rgba(0, 187, 153, 0.9);
      bottom: -7px;
    }
    &.place-left::after {
      border-left-color: rgba(0, 187, 153, 0.9);
      right: -7px;
    }
    &.place-right::after {
      border-right-color: rgba(0, 187, 153, 0.9);
      left: -7px;
    }
    &.place-bottom::after {
      border-bottom-color: rgba(0, 187, 153, 0.9);
      top: -7px;
    }

    &.show {
      opacity: 1;
    }
  }
`;
