import styled from 'styled-components';

const TimeSuggestion = styled.li`
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  padding: 6px 17px;
`;

const TimeImage = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.common.white};
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-radius: 2px;
  margin-right: 9px;
`;

const MetaInfo = styled.div`
  display: flex;
  flex-direction: column;
  .time-label {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text?.default};
    line-height: 17px;
  }
  .time-info {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray5};
    line-height: 15px;
  }
`;

const Root = ({ item: { icon, name, info }, isSelected, onSelect }) => (
  <TimeSuggestion
    role="option"
    className={isSelected ? 'selected' : ''}
    onClick={onSelect}
  >
    <TimeImage>{icon}</TimeImage>
    <MetaInfo>
      <span className="time-label">{name}</span>
      {info && <span className="time-info">{info}</span>}
    </MetaInfo>
  </TimeSuggestion>
);

export default Root;
