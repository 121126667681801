import React from 'react';
import { Picker, PickerProps } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

const EmojiPicker = (props: PickerProps) => {
  return (
    <div>
      <Picker {...props} />
    </div>
  );
};

export default EmojiPicker;
