import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Help } from 'src/assets/icons/help.svg';

import { ticketingLogos } from '../config';

import { entitiesList } from 'src/constants/entities';
import { flowNotifications } from 'src/constants/flows';

import ConfigureFlowBlock from '../ConfigureFlowBlock';
import SimpleSelect from 'src/components/Select';
import Checkbox from 'src/components/Checkbox';
import { Tooltip } from 'src/components/Tooltip/style';

import { MessageStyled, CheckListStyled, SubTitleStyled } from '../style';
import { NotificationsStyled, WrapperStyled } from './style';
import { useTheme } from 'styled-components';

const EntityEditor = ({
  error,
  flow,
  onChangeEntity,
  onChangeNotification,
  ticketService,
}) => {
  const theme = useTheme();
  return (
    <ConfigureFlowBlock title="When a new" icon={ticketingLogos[ticketService]}>
      {error && <MessageStyled>{error}</MessageStyled>}
      <SimpleSelect
        name="entity"
        value={
          entitiesList.filter((entity) => entity.value === flow.entityType)[0]
        }
        onChange={(entityType) => onChangeEntity(entityType)}
        options={entitiesList}
      />
      {/* {flow?.entityType && (
        <Fragment>
          <NotificationsStyled>
            <SubTitleStyled>Notifications</SubTitleStyled>
            <CheckListStyled>
              {flowNotifications.map((notification) => (
                <WrapperStyled key={notification.code}>
                  <Checkbox
                    className={`entity-checkbox-${notification.code}`}
                    id={notification.id}
                    checked={flow.notifications.some(
                      (item) => notification.code === item.code
                    )}
                    onChange={(event) =>
                      onChangeNotification(event, notification)
                    }
                  >
                    {notification.label}
                    {notification.tooltipText && (
                      <Fragment>
                        <Help
                          data-tip
                          data-for={notification?.code}
                          fill={theme.colors.gray7}
                          width="16"
                          height="16"
                        />
                        <Tooltip id={notification.code} effect="solid">
                          <div>{notification.tooltipText}</div>
                        </Tooltip>
                      </Fragment>
                    )}
                  </Checkbox>
                </WrapperStyled>
              ))}
            </CheckListStyled>
          </NotificationsStyled>
        </Fragment>
      )} */}
    </ConfigureFlowBlock>
  );
};

EntityEditor.propTypes = {
  error: PropTypes.string,
  ticketService: PropTypes.string,
  flow: PropTypes.objectOf(PropTypes.any),
  onChangeEntity: PropTypes.func.isRequired,
  onChangeNotification: PropTypes.func.isRequired,
};

EntityEditor.defaultProps = {
  error: '',
  flow: {},
  ticketService: null,
};

export default EntityEditor;
