import styled, { css } from 'styled-components';

export const SimpleInputStyled = styled.input`
  width: 100%;
  max-width: 300px;
  border: 1px solid transparent;
  border-radius: 0;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text?.default};
  background-color: ${({ theme }) => theme.colors.background.secondary};

  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
        padding: 6px 8px;
      `;
      default:
        return `
        padding: 12px 10px;
      `;
    }
  }}

  &:hover:not(:disabled) {
    border-color: ${({ theme }) => theme.colors.gray5};
  }

  &:focus:not(:disabled) {
    outline: none;
    border-color: ${({ theme }) => theme.colors.brand.light.hover};
  }

  ${({ isError }) =>
    isError &&
    css`
      &,
      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.colors.red2};
      }
    `}
`;
