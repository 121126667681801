import styled from 'styled-components';

export const WrapperStyled = styled.div``;

export const TitleStyled = styled.h3`
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  letter-spacing: -0.43;
  color: ${({ theme }) => theme.colors.text?.label};
`;

export const IconWrapper = styled.div`
  margin-right: 12px;
`;

export const BoxStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ noSpace }) => !noSpace && 'padding: 20px;'}
  border: 1px solid ${({ theme }) => theme.colors.gray4};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.background?.default};
`;

export const ContentStyled = styled.div`
  flex-grow: 1;
`;

export const LoaderStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 5px;
  background-color: ${({ theme }) =>
    rgba(theme.colors.background?.default, 0.5)};

  svg {
    width: 38px;
    height: 38px;
  }
`;
