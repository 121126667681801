import React, { useState } from 'react';
import { components } from 'react-select';
import { ReactComponent as InboxLogo } from 'src/assets/icons/inbox-logo.svg';
import { SelectAsync } from 'src/components';
import { OffsetButtomStyled, SubTitleStyled } from '../style';
import {
  FooterStyled,
  ListItem,
  SelectedValue,
  StyledContainer,
  TitleStyled,
} from './style';

const SingleValue = ({ children, data, ...props }) => (
  <components.SingleValue {...props}>
    <SelectedValue isValue>
      <span>{data.logo}</span>
      <span className="label">{children}</span>
    </SelectedValue>
  </components.SingleValue>
);

const Option = (props) => {
  return (
    <ListItem
      isFocused={props.isFocused}
      onClick={() => props.selectOption(props.data)}
    >
      <span>{props.data.logo}</span>
      <span className="label">{props.children}</span>
    </ListItem>
  );
};

const InboxEditor = ({
  channelData,
  onChangeChannel,
  getChannels,
  children,
}) => {
  const [isButtonAddChannel, setIsButtonAddChannel] = useState(true);

  const promiseOptionsChannels = (like) => {
    return getChannels(like).then((data = []) => {
      if (!channelData?.label) {
        onChangeChannel(data[0]);
      }
      return data;
    });
  };

  return (
    <StyledContainer>
      <TitleStyled>Send a message</TitleStyled>
      <div className="card">
        <div className="box">
          <InboxLogo width="30" height="30" />
          <div className="select-wrapper">
            <SubTitleStyled>Inbox Channel</SubTitleStyled>
            <OffsetButtomStyled>
              <SelectAsync
                key="inbox-channel"
                onChange={onChangeChannel}
                getOptionValue={(option) => option.name}
                getOptionLabel={(option) => option.friendly_name}
                value={channelData}
                placeholder="Start typing channel name"
                options={promiseOptionsChannels}
                components={{ SingleValue, Option }}
                shouldControlInputValue={false}
              />
            </OffsetButtomStyled>
            <FooterStyled>{children}</FooterStyled>
          </div>
        </div>
      </div>
    </StyledContainer>
  );
};

InboxEditor.defaultProps = {
  onChangeChannel: () => {},
  channelData: {},
  getChannels: () => Promise.resolve([]),
};

export default InboxEditor;
