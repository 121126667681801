import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { initialTabsNames } from '../config';
import { useFlowAction } from '../hook/useFlowAction';
import { useNonInitialEffect } from '../hook/useNonInitialEffect';
import Input from './Input';

const InputContainer = ({
  actionsList,
  currentAction,
  setCurrentAction,
  getOptions,
}) => {
  const [text, setText] = useState(
    currentAction.form_data?.reply || currentAction.form_data?.note || ''
  );
  const [filterText, setFilterText] = useState('');
  const [firstAction, setFirstAction] = useState({});
  const [activeTab, setActiveTab] = useState(
    currentAction.tab || initialTabsNames.ACTION_PLATFORM
  );
  const debouncedChange = useCallback(
    debounce((handler) => handler(), 200),
    []
  );
  const {
    tabs,
    loading,
    breadcrumb,
    suggestions,
    isSelectable,
    setBreadcrumb,
    suggestionsState,
    unqueueBreadcrumb,
    toggleSuggestionsList,
    onUpdateSuggestionState,
  } = useFlowAction({
    activeTab,
    getOptions,
    actionsList,
    currentAction,
  });

  const changeFetchedText = (params) =>
    getOptions({
      ...tabs[activeTab],
      params,
    })
      .then((result) => {
        if (result.length > 0) {
          setCurrentAction({
            ...currentAction,
            ...tabs[activeTab],
            id: tabs[activeTab].id,
            tab: activeTab,
            actionId: tabs[activeTab].id,
            selectedOptionId: tabs[activeTab].id,
            payload: result[0],
            text: result[0][activeTab.toLowerCase()],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

  const onChangeTab = (tabKey) => {
    if (activeTab === tabKey) {
      return;
    }
    const tabSuggestions = tabs[tabKey]?.actions || suggestions[tabKey];
    setActiveTab(tabKey);
    setFilterText('');
    toggleSuggestionsList(tabKey, tabSuggestions);
    setCurrentAction({
      ...currentAction,
      ...tabs[tabKey],
      actionId: tabs[tabKey].id,
      selectedOptionId: tabs[tabKey].id,
      tab: tabKey,
    });
  };

  const handleChange = (option) => {
    const selectedAction = option?.form_data;
    const parentFromBreadcumb = breadcrumb[breadcrumb.length - 1];
    if (selectedAction) {
      setCurrentAction({
        ...currentAction,
        form_data: option?.form_data,
        text: text,
        name: selectedAction.name,
        actionId: parentFromBreadcumb?.id || tabs[activeTab].id,
        id: parentFromBreadcumb?.id || tabs[activeTab].id,
        selectedOptionId: selectedAction.id,
        payload: {
          ...option,
          form_data: {
            ...option?.form_data,
            actions_structure: [tabs[activeTab], ...breadcrumb],
          },
        },
      });
    }
  };

  const selectByIndex = (idx) => {
    const action = suggestionsState.tabSuggestions[idx];
    const selectedAction = action.form_data || action;

    const payload = action.payload || {
      form_data: action.form_data,
      actions: action.actions,
    };
    if (selectedAction) {
      setCurrentAction({
        ...currentAction,
        text: text,
        name: selectedAction.name,
        actionId: tabs[activeTab].id,
        form_data: action.form_data,
        selectedOptionId: selectedAction.id,
        payload,
      });
    }
  };

  const onSelectWithMouse = (selectedIndex, isMore = false) => {
    if (isMore) {
      onUpdateSuggestionState({ activeSuggestion: selectedIndex });
    } else {
      selectByIndex(selectedIndex);
    }
  };

  const getPathToAction = () => {
    const paths = [];
    const parentActionId = currentAction.payload.actions?.selected_action_id;
    const selectedAction = currentAction.payload.form_data;
    const isFromTabs = Object.values(tabs).find(
      (tab) => tab.id === parentActionId
    );

    if (!isFromTabs || selectedAction.actions_structure?.some(item => item.name === 'Actions')) {
      actionsList.forEach((action) => {
        action.actions.forEach((option) => {
          const optionId = option.form_data?.id || option.id;
          if (optionId === parentActionId) {
            paths.push(action);
            paths.push(option);
          }
          // related to automate/itglue
          if (optionId === selectedAction.id) {
            paths.push(action);
          }
        });
      });
      setActiveTab(initialTabsNames.ACTION_PLATFORM);
      debouncedChange(() => {
        const lastPath = paths[paths.length - 1];
        setBreadcrumb(paths);
        toggleSuggestionsList(
          initialTabsNames.ACTION_PLATFORM,
          lastPath?.actions || [],
          lastPath // automate / itglue has no id
        );
      });
    } else {
      setActiveTab(isFromTabs.code);
    }
  };

  // to prevent first render no to fetch initially
  useNonInitialEffect(() => {
    if (!isSelectable && currentAction.payload) {
      const { form_data } = currentAction?.payload;
      setText(form_data?.[currentAction.tab.toLowerCase()]);
    }
  }, [activeTab, currentAction.order]);

  useNonInitialEffect(() => {
    const form_data = currentAction?.payload?.form_data;
    const renderedText =
      form_data && form_data?.[currentAction.tab?.toLowerCase()];
    if (!isSelectable && text !== renderedText) {
      const param = {
        [activeTab.toLowerCase()]: text,
      };
      debouncedChange(() => changeFetchedText(param));
    }
  }, [text]);

  useEffect(() => {
    if (currentAction.payload) {
      getPathToAction();
    }
  }, [tabs]);

  return (
    <div
      style={{
        cursor: loading && 'progress',
      }}
    >
      <Input
        tabs={tabs}
        text={text}
        setText={setText}
        isLoading={loading}
        activeTab={activeTab}
        getOptions={getOptions}
        breadcrumb={breadcrumb}
        filterText={filterText}
        onChangeTab={onChangeTab}
        isSelectable={isSelectable}
        handleChange={handleChange}
        currentAction={currentAction}
        setFilterText={setFilterText}
        suggestionsState={suggestionsState}
        unqueueBreadcrumb={unqueueBreadcrumb}
        onSelectWithMouse={onSelectWithMouse}
      />
    </div>
  );
};

InputContainer.propTypes = {
  getOptions: PropTypes.func,
  setCurrentAction: PropTypes.func,
  actionsList: PropTypes.array.isRequired,
  currentAction: PropTypes.object.isRequired,
};

InputContainer.defaultProps = {
  getOptions: () => new Promise(() => []),
  setCurrentAction: () => {},
};

export default InputContainer;
