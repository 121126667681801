import { getTheme } from 'src';
import React from 'react';
import ReactAvatar from 'react-avatar';
import toColor from 'string-to-color';
import tinycolor from 'tinycolor2';

const Avatar = ({ color, fgColor, isManual, ...props }) => {
  const avColor = color || toColor(props.name);
  const isLightColor = tinycolor(avColor).isLight();

  const bgColor = isLightColor
    ? tinycolor(avColor).brighten(20).toString()
    : tinycolor(avColor).lighten(53).brighten().toString();
  const textColor = tinycolor(bgColor).isDark()
    ? getTheme().colors.gray2
    : fgColor || getTheme().colors.gray5;

  return (
    <ReactAvatar
      textSizeRatio={2.5}
      color={!isManual ? bgColor : color}
      fgColor={textColor}
      {...props}
    />
  );
};

Avatar.defaultProps = {
  size: 37,
  round: true,
  isManual: false,
};

export default Avatar;
