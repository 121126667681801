import React from 'react';
import { getRandomAvatarColor, Select } from 'src';
import Avatar from 'src/components/Avatar';
import { styles } from 'src/components/Select/style';
import { SelectedItem } from './style';

const MultiValueLabel = ({ data, children }) => {
  return (
    <SelectedItem>
      <Avatar
        color={getRandomAvatarColor(data?.label || String(data?.value))}
        isManual={true}
        size={21}
        name={data?.label || data?.value}
      />
      <div className="picker-item-wrapper">
        <span className="picker-item-name">{children}</span>
        <span className="picker-item-email">{data.email}</span>
      </div>
    </SelectedItem>
  );
};

const TeamMembers = ({ size, customStyles, components, ...rest }) => {
  const selectCustomStyles = {
    multiValue: (state) => ({
      border: `1px solid ${state.theme.colors.border?.default}`,
      backgroundColor: state.theme.colors.background.secondary,
      overflow: 'hidden',
      borderRadius: 50,
      height: 34,
      padding: 4,
    }),
    multiValueRemove: (state) => ({
      color: state.theme.colors.gray5,
      width: 25,
      display: 'flex',
      justifyContent: 'center',
    }),
    valueContainer: (state) => ({
      padding: '5px 10px',
    }),
    ...customStyles,
  };
  return (
    <Select
      components={{
        MultiValueLabel: MultiValueLabel,
        Option: Select.AvatarOption,
        MultiValueRemove: Select.MultiValueRemove,
        ...components
      }}
      styles={styles({
        size,
        customStyles: selectCustomStyles,
      })}
      key="team_members"
      isMulti
      {...rest}
    />
  );
};

export default TeamMembers;
