export const ticketingLogos = {
  // BMS: <StyledLogo src={require('../../assets/images/bms-logo.png')} alt="bms" width="26" />,
  // CONNECTWISE: (
  //   <StyledLogo
  //     src={require('../../assets/images/connectwise-logo.png')}
  //     alt="connectwise"
  //     width="26"
  //   />
  // ),
};
export const avatarColors = ['#D4F2E9', '#D6DBFC', '#FCECD6'];

export const isInputTypeElement = (event) => {
  const nodeName = event.nodeName.toLowerCase();
  if (
    event.nodeType === 1 &&
    (nodeName === 'textarea' || nodeName === 'input' || event.isContentEditable)
  ) {
    return true;
  }
  return false;
};
