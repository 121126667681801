export const entitiesList = [
  {
    value: 'ticket',
    label: 'Ticket',
  },
  {
    value: 'opportunity',
    label: 'Opportunity',
  },
];
