import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'src/assets/icons';

import {
  WrapperStyled,
  LoaderStyled,
  TitleStyled,
  BoxStyled,
  ContentStyled,
  IconWrapper,
} from './style';

const ConfigureFlowBlock = ({
  children,
  title,
  icon,
  isLoading,
  className,
  noSpace = false,
}) => (
  <WrapperStyled className={className}>
    <TitleStyled>{title}</TitleStyled>
    <BoxStyled noSpace={noSpace}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <ContentStyled>{children}</ContentStyled>
      {isLoading && <LoaderStyled>{Loader}</LoaderStyled>}
    </BoxStyled>
  </WrapperStyled>
);

ConfigureFlowBlock.propTypes = {
  className: PropTypes.string,
};

ConfigureFlowBlock.defaultProps = {
  className: '',
  isLoading: false,
  icon: null,
  title: '',
  children: null,
};

export default ConfigureFlowBlock;
