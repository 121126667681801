import PropTypes from 'prop-types';
import { animated as a } from 'react-spring';
import styled from 'styled-components';
import { ReactComponent as Checked } from '../../../../assets/icons/fi_check.svg';
import { actionIcons } from '../config';
import More from './more';
import { ContentBox } from './styles';
const Suggestion = styled(a.li)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 6px 17px;
  width: 100%;
`;

const Image = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.common.white};
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-radius: 2px;
  margin-right: 9px;
`;

const MetaInfo = styled.div`
  display: flex;
  flex-direction: column;
  .status-label {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text?.default};
    line-height: 17px;
  }
`;

const ActionPlatform = ({
  item,
  onSelect,
  isSelected,
  hasOptions,
  onChooseMore,
}) => {
  const { name, code, icon: initIcon } = item;
  const icon = initIcon || actionIcons[code?.toUpperCase()];

  return (
    <Suggestion
      className={isSelected ? 'selected' : ''}
      role="option"
      onClick={onChooseMore}
    >
      <ContentBox hasOptions={hasOptions}>
        {icon && (
          <Image>
            <img {...icon.props} />
          </Image>
        )}
        <MetaInfo>
          <span className="status-label">{name}</span>
        </MetaInfo>
        {isSelected && <Checked />}
      </ContentBox>
      {hasOptions && <More />}
    </Suggestion>
  );
};

ActionPlatform.propTypes = {
  item: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onChooseMore: PropTypes.func,
  isSelected: PropTypes.bool,
  hasOptions: PropTypes.bool,
  fromSelect: PropTypes.bool,
};

ActionPlatform.defaultProps = {
  isSelected: false,
  hasOptions: false,
  fromSelect: false,
  onSelect: () => {},
  onChooseMore: () => {},
};

export default ActionPlatform;
