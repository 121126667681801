export const Spinner = (
  <svg
    id="spinner"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    style={{ background: 'none' }}
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      ng-attr-stroke="{{config.color}}"
      ng-attr-stroke-width="{{config.width}}"
      ng-attr-r="{{config.radius}}"
      ng-attr-stroke-dasharray="{{config.dasharray}}"
      stroke="#FFFFFF"
      opacity="0.8"
      strokeWidth="15"
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
      transform="rotate(168 50 50)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        calcMode="linear"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

export const Loader = (
  <svg
    id="loader"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    style={{ background: '0 0' }}
  >
    <rect x="45" y="1.5" rx="45" ry="1.5" width="10" height="23" fill="#0815B3">
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.1s"
        begin="-1.0083333333333335s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="45"
      y="1.5"
      rx="45"
      ry="1.5"
      width="10"
      height="23"
      fill="#0815B3"
      transform="rotate(30 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.1s"
        begin="-0.9166666666666666s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="45"
      y="1.5"
      rx="45"
      ry="1.5"
      width="10"
      height="23"
      fill="#0815B3"
      transform="rotate(60 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.1s"
        begin="-0.8250000000000001s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="45"
      y="1.5"
      rx="45"
      ry="1.5"
      width="10"
      height="23"
      fill="#0815B3"
      transform="rotate(90 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.1s"
        begin="-0.7333333333333334s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="45"
      y="1.5"
      rx="45"
      ry="1.5"
      width="10"
      height="23"
      fill="#0815B3"
      transform="rotate(120 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.1s"
        begin="-0.6416666666666667s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="45"
      y="1.5"
      rx="45"
      ry="1.5"
      width="10"
      height="23"
      fill="#0815B3"
      transform="rotate(150 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.1s"
        begin="-0.55s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="45"
      y="1.5"
      rx="45"
      ry="1.5"
      width="10"
      height="23"
      fill="#0815B3"
      transform="rotate(180 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.1s"
        begin="-0.4583333333333333s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="45"
      y="1.5"
      rx="45"
      ry="1.5"
      width="10"
      height="23"
      fill="#0815B3"
      transform="rotate(210 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.1s"
        begin="-0.3666666666666667s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="45"
      y="1.5"
      rx="45"
      ry="1.5"
      width="10"
      height="23"
      fill="#0815B3"
      transform="rotate(240 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.1s"
        begin="-0.275s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="45"
      y="1.5"
      rx="45"
      ry="1.5"
      width="10"
      height="23"
      fill="#0815B3"
      transform="rotate(270 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.1s"
        begin="-0.18333333333333335s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="45"
      y="1.5"
      rx="45"
      ry="1.5"
      width="10"
      height="23"
      fill="#0815B3"
      transform="rotate(300 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.1s"
        begin="-0.09166666666666667s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="45"
      y="1.5"
      rx="45"
      ry="1.5"
      width="10"
      height="23"
      fill="#0815B3"
      transform="rotate(330 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.1s"
        begin="0s"
        repeatCount="indefinite"
      />
    </rect>
  </svg>
);
