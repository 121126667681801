import React from 'react';
import PropTypes from 'prop-types';

const CustomRemoveButton = ({ label, rules, className, handleOnClick }) => (
  <button type="button" className={className} onClick={handleOnClick} label={label} rules={rules}>
    ×
  </button>
);

CustomRemoveButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.any),
  handleOnClick: PropTypes.func.isRequired,
};

CustomRemoveButton.defaultProps = {
  label: '',
  className: '',
  rules: [],
};

export default CustomRemoveButton;
