import styled, { createGlobalStyle } from 'styled-components';
import TimePicker from 'rc-time-picker';
export const PopupStyles = createGlobalStyle`
.rc-time-picker-panel-inner {
  background: ${({ theme }) => theme.colors.background.default}
}
.rc-time-picker-panel-select li:hover {
  background: ${({ theme }) => theme.colors.action.hover}
}
.rc-time-picker-panel-select li.rc-time-picker-panel-select-option-selected {
  background: ${({ theme }) => theme.colors.action.active}
}

`;
export const TimePickerStyled = styled(TimePicker)`
  background: red;
  input {
    width: 100%;
    max-width: 180px;
    border-color: ${({ theme }) => theme.colors.border.default};
    border-radius: 0;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.common.contrast};

    ${({ size }) => {
      switch (size) {
        case 'small':
          return `
        padding: 6px 8px;
      `;
        default:
          return `
        padding: 12px 10px;
      `;
      }
    }}

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.brand.light.hover};
    }
  }

  .rc-time-picker-clear {
    right: 5px;
  }
  .rc-time-picker-panel-inner {
    background: ${({ theme }) => theme.colors.action.variant1};
  }

  .rc-time-picker-clear-icon:after {
    font-size: 14px;
    font-weight: 600;
  }
`;
