import styled from 'styled-components';

export const WrapperStyled = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.43px;
  color: ${({ theme }) => theme.colors.text.label};
  cursor: pointer;
`;

export const ControlStyled = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  background-color: ${({ theme }) => theme.colors.lightGray1};
  box-sizing: border-box;
  border-radius: 4px;
  svg {
    display: block;
    opacity: 0;
    transition: all 0.1s;
  }
`;

export const TextStyled = styled.span`
  margin-left: 8px;
`;

export const InputStyled = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
  &:checked ~ ${ControlStyled} svg,
  &:indeterminate ~ ${ControlStyled} svg {
    top: -4px;
    left: 3px;
    opacity: 1;
  }
  &:checked ~ ${ControlStyled}, &:indeterminate ~ ${ControlStyled} {
    border: 1px solid transparent;
    background-color: ${({ theme }) => theme.colors.brand.light.base};
  }
`;
