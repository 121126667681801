import styled from 'styled-components';
import { ReactComponent as Checked } from '../../../../assets/icons/fi_check.svg';
import { actionIcons } from '../config';
import More from './more';
import { ContentBox } from './styles';

const Suggestion = styled.li`
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  padding: 6px 17px;
`;

const Image = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.common.white};
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-radius: 2px;
  margin-right: 9px;
`;

const MetaInfo = styled.div`
  display: flex;
  flex-direction: column;
  .status-label {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text?.default};
    line-height: 17px;
  }
`;

const Action = ({ item, onSelect, isSelected, activeTab, hasOptions }) => {
  const { name, command } = item;
  const icon = actionIcons[activeTab];

  return (
    <Suggestion className={isSelected ? 'selected' : ''} role="option">
      <ContentBox hasOptions={hasOptions} onClick={onSelect}>
        {icon && <Image>{icon}</Image>}
        <MetaInfo>
          <span className="status-label">{name}</span>
        </MetaInfo>
        {isSelected && <Checked />}
      </ContentBox>
      {hasOptions && <More />}
    </Suggestion>
  );
};

export default Action;
