import styled from 'styled-components';

export const LabelStyled = styled.label`
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

export const ControlStyled = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${({ width }) => `${width}px` || '78px'};
  height: ${({ size }) => `${size === 'small' ? 16 : 30}px`};
  padding: 3px;
  box-sizing: border-box;
  border-radius: 15px;
  background: ${({ theme }) => theme.colors.gray1};
  transition: background 0.2s;
  &::before {
    content: '';
    position: absolute;
    left: ${({ size }) => `${size === 'small' ? 1.5 : 3}px`};
    display: block;
    width: ${({ size }) => `${size === 'small' ? 12 : 24}px`};
    height: ${({ size }) => `${size === 'small' ? 12 : 24}px`};
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.gray5};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.08);
    transition: left 0.2s, background 0.2s;
  }
`;

export const ControlTextStyled = styled.span`
  padding: ${({ checked }) => (checked ? '0 32px 0 16px' : '0 16px 0 32px')};
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray5};
  user-select: none;
`;

export const InputStyled = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;

  &:checked ~ ${ControlStyled} {
    justify-content: flex-start;
    background: ${({ theme }) => theme.colors.brand.light.base};
    &::before {
      background: ${({ theme }) => theme.colors.common.white};
      left: ${({ size }) =>
        `calc(100% - ${size === 'small' ? 12 : 24}px - ${
          size === 'small' ? 1.5 : 3
        }px)`};
    }
  }

  &:checked ~ ${ControlStyled} ${ControlTextStyled} {
    left: 0;
    color: ${({ theme }) => theme.colors.common.white};
  }
`;
