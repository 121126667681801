import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  actionIcons,
  excludedFetchActionList,
  excludedTabsList,
  initialSuggestionsState,
  initialTabs,
  initialTabsNames,
} from '../config';

const tabNormalizer = (tab) => ({
  [tab.name?.toUpperCase()]: {
    ...tab,
    id: tab.id,
    name: tab.name,
    suggestionType: tab.name?.toUpperCase(),
    icon: actionIcons[tab.name?.toUpperCase()],
    code: tab.name?.toUpperCase(),
  },
});

export const useFlowAction = ({
  actionsList,
  activeTab,
  getOptions,
  currentAction,
  debouncedChange,
}) => {
  const { is_chainable, form_data, payload, selectedOptionId, actions } =
    currentAction;
  const [loading, setLoading] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const isSelectable = !excludedFetchActionList.includes(activeTab);
  const [suggestionsState, setSuggestionsState] = useState(
    initialSuggestionsState
  );
  const tabs = useMemo(() => {
    let actions = {
      [initialTabsNames.REPLY]: initialTabs[initialTabsNames.REPLY],
      [initialTabsNames.NOTE]: initialTabs[initialTabsNames.NOTE],
    };
    const defaultTabs = actionsList?.find(
      (item) => item.command === 'connectwise'
    );

    if (defaultTabs) {
      defaultTabs.actions.forEach((tab) => {
        if (!excludedTabsList.includes(tab.name?.toLowerCase())) {
          actions = { ...actions, ...tabNormalizer(tab) };
        }
      });
      actions[initialTabsNames.ACTION_PLATFORM] = {
        ...initialTabs.ACTION_PLATFORM,
        actions: actionsList,
      };
    }

    return actions;
  }, [actionsList]);

  const initialSuggestions = useMemo(() => {
    let newTabs = {};
    let actionTabItems = [...actionsList];
    if (tabs) {
      Object.keys(tabs).forEach((tab) => {
        newTabs = {
          ...newTabs,
          [tab]: [],
        };
      });
      // to set code field into actions tab in order to show parent icon
      actionTabItems = actionTabItems.map((action) => {
        const { actions, code } = action;
        let newActions = actions.map((item) => ({ ...item, code }));
        return { ...action, actions: newActions };
      });
      newTabs[initialTabsNames.ACTION_PLATFORM] = [...actionTabItems];
    }
    return newTabs;
  }, [tabs]);

  const [suggestions, setSuggestions] = useState({ ...initialSuggestions });

  const enqueueBreadcrumb = (brdcrmb) => {
    const newBreadcrumb = [...breadcrumb];
    newBreadcrumb.push(brdcrmb);
    setBreadcrumb(newBreadcrumb);
  };

  const unqueueBreadcrumb = () => {
    let active = breadcrumb[breadcrumb.length - 2];
    let previous = breadcrumb[breadcrumb.length - 1] || tabs[activeTab];
    const filteredBreadcrumb = breadcrumb.filter(
      (item) => !previous.name.includes(item.name)
    );
    const tabSuggestions = active?.actions || suggestions[activeTab] || [];
    toggleSuggestionsList(activeTab, tabSuggestions);
    setBreadcrumb(filteredBreadcrumb);
  };

  const onUpdateSuggestionState = (newState) => {
    const { activeSuggestion } = newState;
    const tabSuggestions = suggestionsState.tabSuggestions || [];
    const activeSuggestionState = tabSuggestions[activeSuggestion] || null;
    const mutatedList = activeSuggestionState.actions?.map((action) => ({
      ...action,
      id: action.form_data?.id || action.id,
      name: action.form_data?.name || action.name,
      icon: activeSuggestionState.icon,
    }));

    toggleSuggestionsList(activeTab, mutatedList, activeSuggestionState);
    enqueueBreadcrumb({ ...activeSuggestionState, actions: mutatedList });
  };

  const hideSuggestions = () =>
    setSuggestionsState((prev) => ({ ...prev, showSuggestions: false }));

  const toggleSuggestionsList = useCallback(
    (tab, tabSuggestions, activeSuggestionState) => {
      const activeSuggestion = activeSuggestionState || tabs[tab];
      const filteredSuggestions = tabSuggestions?.filter(
        (action) =>
          !excludedFetchActionList.includes(action.name?.toUpperCase())
      );
      const withoutMiddleAction =
        !activeSuggestion?.id && filteredSuggestions?.length > 0;
      const hasChain = !!filteredSuggestions?.find(
        (suggestion) =>
          suggestion.is_chainable || suggestion.actions?.length > 0
      );

      setSuggestionsState((prev) => ({
        ...prev,
        ...activeSuggestion,
        tab,
        id: tabs[tab]?.id,
        payload: activeSuggestion?.payload || activeSuggestion || tabs[tab],
        command: activeSuggestion?.command || tabs[tab]?.command,
        showSuggestions: hasChain || withoutMiddleAction,
        showSelect:
          !excludedFetchActionList.includes(tab) &&
          !hasChain &&
          !!activeSuggestion?.id,
        tabSuggestions: filteredSuggestions,
      }));
    },
    [activeTab]
  );

  useEffect(() => {
    toggleSuggestionsList(activeTab);
  }, []);

  useEffect(() => {
    setSuggestions({ ...initialSuggestions, showSelect: isSelectable });
  }, [initialSuggestions]);

  useEffect(() => {
    setBreadcrumb([]);
  }, [activeTab]);

  return {
    tabs,
    loading,
    breadcrumb,
    suggestions,
    isSelectable,
    setBreadcrumb,
    hideSuggestions,
    suggestionsState,
    unqueueBreadcrumb,
    toggleSuggestionsList,
    onUpdateSuggestionState,
  };
};
