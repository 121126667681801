import PropTypes from 'prop-types';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AsyncPaginate as Select } from 'react-select-async-paginate';
import { useTheme } from 'styled-components';

import { debouncePromise } from '../../utils';
import {
  AvatarMultiValueLabel,
  AvatarOption,
  DropdownIndicator,
  GroupLabel,
  Menu,
} from './index';
import * as Styled from './style';
import { styles } from './style';

const AsyncSelectPaginate = ({
  customStyles,
  className,
  components,
  defaultOptions,
  formatGroupLabel,
  filterOption,
  getOptionValue,
  getOptionLabel,
  isDisabled,
  inputValue,
  label,
  name,
  noOptionsMessage,
  size,
  selectRef,
  shouldControlInputValue,
  onChange,
  options,
  onKeyDown,
  onInputChange,
  placeholder,
  title,
  value,
  ...restProps
}) => {
  const theme = useTheme();
  const [selectInputValue, setSelectInputValue] = useState('');
  const optionsPage = useRef({
    nextPage: 1,
    nextPageInSearch: 1,
    isPageInSearch: false,
  });
  const setOptionsPage = values => {
    optionsPage.current = {
      ...optionsPage.current,
      ...values,
    };
  };

  const promiseOptions = useCallback(
    (...args) => {
      const nextPage = optionsPage.current.isPageInSearch
        ? optionsPage.current.nextPageInSearch
        : optionsPage.current.nextPage;

      return options(args[0], nextPage).then(({ data, meta }) => {
        setOptionsPage({
          [optionsPage.current.isPageInSearch
            ? 'nextPageInSearch'
            : 'nextPage']: meta.current_page + 1,
        });

        return {
          options: data,
          hasMore: meta.current_page < meta.last_page,
        };
      });
    },
    [options]
  );

  const handleInputChange = newValue => {
    if (shouldControlInputValue && !onInputChange) {
      setSelectInputValue(newValue);
    }
  };

  const selectInputChange = e => {
    if (e.length > 0) {
      setOptionsPage({
        isPageInSearch: true,
        nextPageInSearch: 1,
      });
    }

    if (onInputChange) onInputChange(e);
    else handleInputChange(e);
  };

  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = scrollHeight - clientHeight - 400;

    return bottomBorder < scrollTop;
  };

  useEffect(() => {
    const inputText = onInputChange ? inputValue : selectInputValue;

    if (inputText === '') {
      setOptionsPage({ isPageInSearch: false });
    }
  }, [inputValue, selectInputValue]);

  return (
    <Fragment>
      {label && <Styled.Label htmlFor={name}>{label}</Styled.Label>}
      <Select
        className={className}
        styles={styles({
          size,
          customStyles,
          shouldControlInputValue: shouldControlInputValue && !onInputChange,
        })}
        debounceTimeout={500}
        ref={selectRef}
        {...(onInputChange ||
          (shouldControlInputValue && {
            inputValue: onInputChange ? inputValue : selectInputValue,
          }))}
        onInputChange={selectInputChange}
        title={title}
        theme={theme}
        value={value}
        components={{
          ...components,
          DropdownIndicator: components?.DropdownIndicator || DropdownIndicator,
        }}
        name={name}
        id={name}
        cacheOptions
        defaultOptions={defaultOptions}
        shouldLoadMore={shouldLoadMore}
        getOptionValue={getOptionValue}
        blurInputOnSelect
        getOptionLabel={getOptionLabel}
        onChange={(newValue, reason) => {
          const label = getOptionLabel(newValue || {}) || '';
          handleInputChange(label);
          onChange(newValue, reason);
        }}
        loadOptions={promiseOptions}
        isDisabled={isDisabled}
        onKeyDown={onKeyDown}
        filterOption={filterOption}
        noOptionsMessage={noOptionsMessage}
        {...(formatGroupLabel && {
          formatGroupLabel:
            formatGroupLabel === true ? GroupLabel : formatGroupLabel,
        })}
        placeholder={placeholder}
        {...restProps}
      />
    </Fragment>
  );
};

AsyncSelectPaginate.AvatarMultiValueLabel = AvatarMultiValueLabel;
AsyncSelectPaginate.AvatarOption = AvatarOption;
AsyncSelectPaginate.Menu = Menu;

AsyncSelectPaginate.propTypes = {
  customStyles: PropTypes.object,
  className: PropTypes.string,
  components: PropTypes.any,
  defaultOptions: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  formatGroupLabel: PropTypes.bool,
  filterOption: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  isDisabled: PropTypes.bool,
  inputValue: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  size: PropTypes.string,
  selectRef: PropTypes.object,
  shouldControlInputValue: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.func,
  onKeyDown: PropTypes.func,
  value: PropTypes.any,
  title: PropTypes.string,
  placeholder: PropTypes.string,
};

AsyncSelectPaginate.defaultProps = {
  customStyles: {},
  className: 'simple-select-async',
  components: {},
  defaultOptions: true,
  formatGroupLabel: false,
  isDisabled: false,
  label: null,
  name: null,
  placeholder: null,
  getOptionLabel: opt => opt?.name,
  shouldControlInputValue: true,
  noOptionsMessage: () => 'No options',
};

export default AsyncSelectPaginate;
