import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect, { components as selectComponents } from 'react-select';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/arrow.svg';
import Avatar from 'src/components/Avatar';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus-small.svg';
import {
  SelectGroupStyled,
  SelectBadgeStyled,
  ListItem,
  OptionBlock,
  SelectedItem,
  Wrapper,
  Label,
  AddNewOptionButton,
  RemoveButtonPlaceholder,
  styles,
} from './style';
import { useTheme } from 'styled-components';
import { getRandomAvatarColor } from 'src';

export const AvatarMultiValueLabel = ({ data, children }) => {
  return (
    <SelectedItem>
      <Avatar
        size={21}
        name={data?.label || data?.value}
        color={getRandomAvatarColor(children)}
        isManual={true}
      />
      <OptionBlock>
        <span className="picker-item-name">{children}4</span>
        {data.email && <span className="email">{data.email}</span>}
      </OptionBlock>
    </SelectedItem>
  );
};

export const AvatarOption = (props) => {
  return (
    <ListItem
      isFocused={props.isFocused}
      onClick={() => props.selectOption(props.data)}
    >
      <Avatar
        size={28}
        name={props.children}
        color={getRandomAvatarColor(props.children)}
        isManual={true}
      />
      <OptionBlock>
        <span className="label">{props.children}</span>
        {props.data?.email && <span className="email">{props.data.email}</span>}
      </OptionBlock>
    </ListItem>
  );
};
export const MultiValueRemove = props => {
  const value = props.selectProps.getOptionValue(props.data)
  const isUnremovable = props.selectProps.unremovableValues && props.selectProps.unremovableValues.includes(value)
  if(isUnremovable){
    return <RemoveButtonPlaceholder />
  }
  return  (
    <selectComponents.MultiValueRemove
      {...props}
    />
  );
};

export const Menu = (props) => {
  const {
    children,
    selectProps: { addNewOptionLabel, onAddNewOptionClick, inputValue },
  } = props;
  const label =
    typeof addNewOptionLabel === 'function'
      ? addNewOptionLabel(inputValue)
      : addNewOptionLabel;
  return (
    <selectComponents.Menu {...props}>
      {children}
      {label && (
        <AddNewOptionButton
          onClick={(e) => {
            if (onAddNewOptionClick) {
              onAddNewOptionClick(e, inputValue);
            }
          }}
          type="button"
        >
          <PlusIcon />
          {label}
        </AddNewOptionButton>
      )}
    </selectComponents.Menu>
  );
};
export const GroupLabel = ({ label, options }) => (
  <SelectGroupStyled>
    <span>{label}</span>
    <SelectBadgeStyled>{options.length}</SelectBadgeStyled>
  </SelectGroupStyled>
);

export const DropdownIndicator = (props) => (
  <selectComponents.DropdownIndicator {...props}>
    <ArrowIcon height={7} />
  </selectComponents.DropdownIndicator>
);

const Select = ({
  className,
  value,
  options,
  title,
  name,
  size,
  customStyles,
  onChange,
  getOptionValue,
  getOptionLabel,
  formatGroupLabel,
  isSearchable,
  isClearable,
  components,
  placeholder,
  label,
  isLoading,
  isDisabled,
  maxWidth,
  ...restProps
}) => {
  const theme = useTheme();
  return (
    <Wrapper maxWidth={maxWidth}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <ReactSelect
        className={className}
        title={title}
        name={name}
        id={name}
        size="small"
        styles={styles({ size, customStyles })}
        classNamePrefix="select"
        value={value}
        isLoading={isLoading}
        isDisabled={isDisabled}
        onChange={onChange}
        options={options}
        theme={theme}
        placeholder={placeholder}
        components={{
          ...components,
          DropdownIndicator: components.DropdownIndicator || DropdownIndicator,
        }}
        isSearchable={isSearchable}
        isClearable={isClearable}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        menuPlacement="auto"
        {...(formatGroupLabel && {
          formatGroupLabel:
            formatGroupLabel === true ? GroupLabel : formatGroupLabel,
        })}
        {...restProps}
      />
    </Wrapper>
  );
};

Select.AvatarMultiValueLabel = AvatarMultiValueLabel;
Select.AvatarOption = AvatarOption;
Select.MultiValueRemove = MultiValueRemove

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any),
  value: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  formatGroupLabel: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  className: PropTypes.string,
  customStyles: PropTypes.objectOf(PropTypes.any),
};

Select.defaultProps = {
  formatGroupLabel: false,
  className: 'simple-select',
  customStyles: {},
  components: {},
};

export default Select;
