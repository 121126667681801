import React from 'react';

const CustomAddAction = ({
  label,
  handleOnClick,
  context: { addRuleActionEl, addGroupActionHandler },
}) =>
  label === '+Rule' ? (
    <button ref={addRuleActionEl} type="button" onClick={handleOnClick}>
      {label}
    </button>
  ) : (
    <button
      type="button"
      onClick={event => addGroupActionHandler(event, handleOnClick)}
    >
      {label}
    </button>
  );

export default CustomAddAction;
