import styled from 'styled-components';

const Time = ({ item, isSelected, onSelect }) => {
  return (
    <TimeSuggestion
      key={item.name}
      role="option"
      className={isSelected ? 'selected' : ''}
      onClick={onSelect}
    >
      <TimeImage>🕓</TimeImage>
      <MetaInfo>
        <span className="time-label">{item.name}</span>
        <span className="time-info">{item.info}</span>
      </MetaInfo>
    </TimeSuggestion>
  );
};

export default Time;

const TimeSuggestion = styled.li`
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  padding: 6px 17px;
`;

const TimeImage = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.common.white};
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-radius: 2px;
  margin-right: 9px;

  .emoji {
    align-items: center;
    width: 16px;
  }
`;

const MetaInfo = styled.div`
  display: flex;
  flex-direction: column;
  .time-label {
    font-size: 14px;
    line-height: 17px;
  }
  .time-info {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray5};
    line-height: 15px;
  }
`;
