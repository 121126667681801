import { animated } from 'react-spring';
import styled from 'styled-components/macro';

export const Suggestion = styled.li`
  cursor: pointer;
  height: 50px;
  width: 100%;
  padding: 10px 20px;
`;

export const NoSuggestions = styled(animated.div)`
  cursor: pointer;
  height: 50px;
  width: 100%;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray5};
  cursor: default;
`;

export const Skeleton = styled.li`
  cursor: pointer;
  height: 50px;
  width: 100%;
  padding: 0;
`;

export const SuggestionsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.common.white};
  .simple-select-async {
    display: flex;
  }
  .title {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.gray6};
    margin: 9px 18px;
  }
`;

export const SuggestionsList = styled.ul`
  margin: 0px;
  padding: 0;
  transition: 0.4s ease-in-out all;
  list-style-type: none;
  max-height: 200px;
  overflow: auto;
  > li {
    cursor: pointer;
    :hover {
      background-color: #f5f5f5;
    }
  }
`;

export const ContentBox = styled.div`
  display: flex;
  align-items: center;
  width: ${({ hasOptions }) => (!hasOptions ? '100%' : 'calc(100% - 50px)')};
  position: relative;
  svg {
    position: absolute;
    right: 10px;
  }
`;

export const SelectBox = styled.div`
  & div[class*='-control'] {
    min-width: 100%;
  }
  & div[class*='-listbox'] {
    max-height: 200px;
  }
  .simple-select-async {
    display: block !important;
  }
`;
