import { createRef, useEffect } from 'react';
import { isInputTypeElement } from '../../config';

export const chatInputRef = createRef();

export const focusChatInput = () => {
  if (!isInputTypeElement(document.activeElement)) {
    chatInputRef.current?.focus();
  }
};

export const useChatInputFocuser = () => {
  const focusInput = (event) => {
    if (!isInputTypeElement(event.target)) {
      focusChatInput();
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', focusInput, false);
    return () => {
      window.removeEventListener('keypress', focusInput, false);
    };
  });
};
